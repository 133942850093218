import { CALL_API, HTTP_METHODS } from "../../../../../setup/constants";
import * as ACTIONS from "../constants";

export const fetchContent = () => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.LOAD_CONTENT_INIT,
      success: ACTIONS.LOAD_CONTENT_SUCCEEDED,
      fail: ACTIONS.LOAD_CONTENT_FAILED,
    },
    endpoint: ACTIONS.ENDPOINT_CONTENT,
    method: HTTP_METHODS.GET,
  },
});

export const updateContent = (contentKey, body) => ({
  type: CALL_API,
  payload: body,
  meta: {
    actions: {
      init: ACTIONS.UPDATE_CONTENT_INIT,
      success: ACTIONS.UPDATE_CONTENT_SUCCEEDED,
      fail: ACTIONS.UPDATE_CONTENT_FAILED
    },
    endpoint: ACTIONS.ENDPOINT_CONTENT + "/" + contentKey,
    method: HTTP_METHODS.PUT,
    auth: true
  }
});

export const uploadImage = (payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.UPLOAD_IMAGE_INIT,
      success: ACTIONS.UPLOAD_IMAGE_SUCCEEDED,
      fail: ACTIONS.UPLOAD_IMAGE_FAILED,
    },
    endpoint: ACTIONS.ENDPOINT_UPLOAD,
    method: HTTP_METHODS.POST,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    auth: true,
  },
});

export const resetImage = () => ({
  type: ACTIONS.UPLOAD_IMAGE_RESET,
});
