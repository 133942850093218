import * as ACTIONS from '../constants'

// Initial State
const statsInitialState = {
  error: null,
  success: false,
  isLoading: false,
  stats: {},
  file: null,
  compareStats: {},
}

/**
 * Update stats store
 * @param  {} state: statsInitialState
 * @param  {} action
 */
const statsReducer = (state = statsInitialState, action) => {
  const { payload, type } = action
  switch  (type) {
    case ACTIONS.FETCH_STATS_INIT : {
      return { ...state, isLoading: true, error: null, stats: {} }
    }
    case ACTIONS.FETCH_STATS_SUCCEDED : {
      return { ...state, stats: payload.data, isLoading: false }
    }
    case ACTIONS.FETCH_STATS_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.COMPARE_STATS_INIT : {
      return { ...state, error: null, compareStats: {} }
    }
    case ACTIONS.COMPARE_STATS_SUCCEDED : {
      return { ...state, compareStats: payload.data, isLoading: false }
    }
    case ACTIONS.COMPARE_STATS_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.DOWNLOAD_STATS_INIT : {
      return { ...state, error: null }
    }
    case ACTIONS.DOWNLOAD_STATS_SUCCEDED : {
      return { ...state, file: payload, isLoading: false }
    }
    case ACTIONS.DOWNLOAD_STATS_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    default: {
      return state
    }
  }
}

export default statsReducer
