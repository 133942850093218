import * as ACTIONS from '../constants'


// Initial State
const anonymousInitialState = {
  isLoading: false,
  success: false,
  error: null
}


/**
 * Update anaymous state
 * @param  {} state: anonymousInitialState
 * @param  {} action
 */
const anonymousReducer = (state = anonymousInitialState, action) => {
  const { payload, type } = action
  switch (type) {
    case ACTIONS.CLEAR_ANONYMOUS_STORE : {
      return { ...state, ...anonymousInitialState }
    }

    case ACTIONS.CONTACT_US_INIT : {
      return { ...state, isLoading: true, success: false }
    }
    case ACTIONS.CONTACT_US_SUCCEDED : {
      const { success } = payload
      return { ...state, isLoading: false, success }
    }
    case ACTIONS.CONTACT_US_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.WITH_DRAWAL_INIT : {
      return { ...state, isLoading: true, success: false }
    }
    case ACTIONS.WITH_DRAWAL_SUCCEDED : {
      const { success } = payload
      return { ...state, isLoading: false, success }
    }
    case ACTIONS.WITH_DRAWAL_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.PRIVATIZE_PLACE_INIT : {
      return { ...state, isLoading: true, success: false }
    }
    case ACTIONS.PRIVATIZE_PLACE_SUCCEDED : {
      const { success } = payload
      return { ...state, isLoading: false, success }
    }
    case ACTIONS.PRIVATIZE_PLACE_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    default: {
      return state
    }
  }
}

export default anonymousReducer
