// URL
export const APP_URL = process.env.REACT_APP_APP_URL
export const APP_URL_API = process.env.REACT_APP_URL_API

export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY

// Environment
export const NODE_ENV = process.env.REACT_APP_NODE_ENV

// Port
export const PORT = process.env.REACT_PORT || 3000
