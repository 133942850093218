import { lazy } from 'react'

// App Imports
import { ROLES } from '../../../setup/constants'

const Appointments = lazy(() => import('../../../modules/admin/appointment/Appointments'))
const AppointmentNew = lazy(() => import('../../../modules/admin/appointment/AppointmentNew'))
const AppointmentEdit = lazy(() => import('../../../modules/admin/appointment/AppointmentEdit'))

// User appointment routes
export const appointmentList = {
    path: '/liste-rdvs',
    component: Appointments,
    auth: true,
    role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN]
}


export const createAppointments = {
    path: '/ajouter-rdv',
    component: AppointmentNew,
    auth: true,
    role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN]
}


export const editAppointments = {
    path: '/modifier-rdv/:param',
    component: AppointmentEdit,
    auth: true,
    role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN]
}
