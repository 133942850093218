import { lazy } from "react";

// App Imports
import { ROLES } from "../../../setup/constants";
const Program = lazy(() => import("../../../modules/user/programs/Program"));
const Programs = lazy(() => import("../../../modules/admin/programs/Programs"));
const ProgramNew = lazy(() =>
  import("../../../modules/admin/programs/ProgramNew")
);
const ProgramEdit = lazy(() =>
  import("../../../modules/admin/programs/ProgramEdit")
);
const ValidatePresence = lazy(() =>
  import("../../../modules/admin/programs/ValidatePresence")
);

// User programs routes
export const programsList = {
  path: "/liste-programmations",
  name: 'programmations',
  component: Programs,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN],
};

export const createPrograms = {
  path: "/ajouter-programmation",
  component: ProgramNew,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN],
};

export const editPrograms = {
  path: "/modifier-programmation/:param",
  component: ProgramEdit,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN],
};

export const validatePresence = {
  path: "/valider-presence/:param",
  component: ValidatePresence,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN],
};

export const eventView = {
  path: "/programmation/:param",
  component: Program,
};
