export const FETCH_CURRENT_USER_INIT = 'FETCH_CURRENT_USER_INIT'
export const FETCH_CURRENT_USER_SUCCEDED = 'FETCH_CURRENT_USER_SUCCEDED'
export const FETCH_CURRENT_USER_FAILED = 'FETCH_CURRENT_USER_FAILED'

export const CLEAR_PROFILE_STORE = 'CLEAR_PROFILE_STORE'

export const UPDATE_PROFILE_INIT = 'UPDATE_PROFILE_INIT'
export const UPDATE_PROFILE_SUCCEDED = 'UPDATE_PROFILE_SUCCEDED'
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED'

export const UPLOAD_AVATAR_INIT = 'UPLOAD_AVATAR_INIT'
export const UPLOAD_AVATAR_SUCCEDED = 'UPLOAD_AVATAR_SUCCEDED'
export const UPLOAD_AVATAR_FAILED = 'UPLOAD_AVATAR_FAILED'

export const FETCH_MY_ACTIVITIES_INIT = 'FETCH_MY_ACTIVITIES_INIT'
export const FETCH_MY_ACTIVITIES_SUCCEDED = 'FETCH_MY_ACTIVITIES_SUCCEDED'
export const FETCH_MY_ACTIVITIES_FAILED = 'FETCH_MY_ACTIVITIES_FAILED'

export const FETCH_MY_APPOINTMENT_INIT = 'FETCH_MY_APPOINTMENT_INIT'
export const FETCH_MY_APPOINTMENT_SUCCEDED = 'FETCH_MY_APPOINTMENT_SUCCEDED'
export const FETCH_MY_APPOINTMENT_FAILED = 'FETCH_MY_APPOINTMENT_FAILED'

export const BUY_PACK_SUCCEDED = 'BUY_PACK_SUCCEDED'
export const SUBSCRIPTION_SUCCEDED = 'SUBSCRIPTION_SUCCEDED'
export const REMOVE_PARTICIPATION_SUCCEDED = 'REMOVE_PARTICIPATION_SUCCEDED'
export const ADD_PARTICIPATION_SUCCEDED = 'ADD_PARTICIPATION_SUCCEDED'
export const CLEAR_AUTH_STORE = "CLEAR_AUTH_STORE"

export const ENDPOINT_MY_ACTIVITIES = "/api/users/me/activities"
export const ENDPOINT_MY_APPOINTMENTS = "/api/users/me/appointments"
export const ENDPOINT_CURRENT_USER = "/api/users/me"
export const ENDPOINT_UPLOAD = "/api/upload"
export const ENDPOINT_CHECK_EMAIL = "/api/auth/emails/verify"

