import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import commonUserTokenReducer from '../../../modules/user/common/token/store/reducers'
import commonAdminTokenReducer from '../../../modules/admin/common/token/store/reducers'
import commonAdminMakeAppointmentReducer from '../../../modules/admin/common/makeAppointment/store/reducers'
import commonAdminParticipationReducer from '../../../modules/admin/common/participation/store/reducers'

import commonMembershipReducer from '../../../modules/user/common/membership/store/reducers'

import authReducer from '../../../modules/common/user-auth/store/reducers'

import contentReducer from '../../../modules/common/content/store/reducers'

import profileReducer from '../../../modules/user/profile/store/reducers'
import homeReducer from '../../../modules/user/home/store/reducers'
import pricingReducer from '../../../modules/user/pricing/store/reducers'
import programReducer from '../../../modules/user/programs/store/reducers'
import supportReducer from '../../../modules/user/support/store/reducers'

import adminProgramReducer from '../../../modules/admin/programs/store/reducers'
import adminTodoListReducer from '../../../modules/admin/todoList/store/reducers'
import adminAppointmentReducer from '../../../modules/admin/appointment/store/reducers'
import adminProfileReducer from '../../../modules/admin/profile/store/reducers'
import adminUsersReducer from '../../../modules/admin/user/store/reducers'
import adminStatsReducer from '../../../modules/admin/stats/store/reducers'
import adminActualitiesReducer from '../../../modules/admin/actualities/store/reducers'

import teamMembersReducer from '../../../modules/admin/team-members/store/reducers'
import teamPartnersReducer from '../../../modules/admin/partners/store/reducers'
import servicesReducer from '../../../modules/admin/services/store/reducers'
import faqReducer from '../../../modules/admin/faq/store/reducers'

import anonymousReducer from '../../../modules/anonymous/store/reducers'


const appReducer = combineReducers({
  commonMembership: commonMembershipReducer,

  commonUserToken: commonUserTokenReducer,
  commonAdminToken: commonAdminTokenReducer,

  commonAdminParticipation: commonAdminParticipationReducer,

  commonAdminMakeApoinment: commonAdminMakeAppointmentReducer,

  auth: authReducer,

  anonymous: anonymousReducer,

  content: contentReducer,

  form: formReducer,

  program: programReducer,
  profile: profileReducer,
  support: supportReducer,
  home: homeReducer,
  pricing: pricingReducer,

  teamMembers: teamMembersReducer,
  teamPartners: teamPartnersReducer,
  services: servicesReducer,
  faq: faqReducer,

  adminProgram: adminProgramReducer,
  adminAppointment: adminAppointmentReducer,
  adminProfile: adminProfileReducer,
  adminUser: adminUsersReducer,
  adminTodoList: adminTodoListReducer,
  adminStats: adminStatsReducer,
  adminActualities: adminActualitiesReducer
})

export default appReducer
