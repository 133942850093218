export const LOAD_CONTENT_INIT = "LOAD_CONTENT_INIT";
export const LOAD_CONTENT_FAILED = "LOAD_CONTENT_FAILED";
export const LOAD_CONTENT_SUCCEEDED = "LOAD_CONTENT_SUCCEEDED";

export const UPDATE_CONTENT_INIT = "UPDATE_CONTENT_INIT";
export const UPDATE_CONTENT_FAILED = "UPDATE_CONTENT_FAILED";
export const UPDATE_CONTENT_SUCCEEDED = "UPDATE_CONTENT_SUCCEEDED";

export const UPLOAD_IMAGE_INIT = "UPLOAD_IMAGE_INIT";
export const UPLOAD_IMAGE_SUCCEEDED = "UPLOAD_IMAGE_SUCCEEDED";
export const UPLOAD_IMAGE_FAILED = "UPLOAD_IMAGE_FAILED";
export const UPLOAD_IMAGE_RESET = "UPLOAD_IMAGE_RESET";

export const ENDPOINT_CONTENT = "/api/content";
export const ENDPOINT_UPLOAD = "/api/upload";
