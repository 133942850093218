import React from "react";
import { connect } from "react-redux";
import InputField from "../../../../ui/InputField";

import { updateContent } from "../store/actions";
import InputTextareaField from "../../../../ui/InputTextareaField";

class TextContentInput extends React.Component {
  state = {
    text: "",
    dirty: false,
  };

  componentDidMount() {
    window.addEventListener("save-content", this.save);
  }

  save = () => {
    if (this.props.contentKey && this.state.dirty) {
      this.setState({ dirty: false });
      this.props.updateContent(this.props.contentKey, this.state);
    }
  }

  render() {
    const { label, type, items, contentKey } = this.props;

    const item = items?.find((item) => item.key === contentKey);

    if (type === "textarea") {
      return (
        <InputTextareaField
          label={label}
          type="text"
          input={{
            defaultValue: item?.text,
            onChange: (evt) => this.setState({ text: evt.target.value, dirty: true }),
          }}
        />
      );
    } else {
      return (
        <InputField
          label={label}
          type="text"
          input={{
            defaultValue: item?.text,
            onChange: (evt) => this.setState({ text: evt.target.value, dirty: true }),
          }}
        />
      );
    }
  }
}

// Get data from reducers
const mapStateToProps = (state) => ({ ...state.content });

export default connect(mapStateToProps, { updateContent })(TextContentInput);
