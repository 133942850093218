export const FETCH_APPOINTMENTS_INIT = 'ADMIN_FETCH_APPOINTMENTS_INIT'
export const FETCH_APPOINTMENTS_SUCCEDED = 'ADMIN_FETCH_APPOINTMENTS_SUCCEDED'
export const FETCH_APPOINTMENTS_FAILED = 'ADMIN_FETCH_APPOINTMENTS_FAILED'

export const FETCH_ALL_USERS_INIT = 'ADMIN_FETCH_ALL_USERS_INIT'
export const FETCH_ALL_USERS_SUCCEDED = 'ADMIN_FETCH_ALL_USERS_SUCCEDED'
export const FETCH_ALL_USERS_FAILED = 'ADMIN_FETCH_ALL_USERS_FAILED'

export const FETCH_AVAILABILITIES_INIT = 'ADMIN_FETCH_AVAILABILITIES_INIT'
export const FETCH_AVAILABILITIES_SUCCEDED = 'ADMIN_FETCH_AVAILABILITIES_SUCCEDED'
export const FETCH_AVAILABILITIES_FAILED = 'ADMIN_FETCH_AVAILABILITIES_FAILED'

export const ADD_AVAILABILITIES_INIT = 'ADMIN_ADD_AVAILABILITY_INIT'
export const ADD_AVAILABILITIES_SUCCEDED = 'ADMIN_ADD_AVAILABILITY_SUCCEDED'
export const ADD_AVAILABILITIES_FAILED = 'ADMIN_ADD_AVAILABILITY_FAILED'

export const REMOVE_AVAILABILITIES_INIT = 'ADMIN_REMOVE_AVAILABILITY_INIT'
export const REMOVE_AVAILABILITIES_SUCCEDED = 'ADMIN_REMOVE_AVAILABILITY_SUCCEDED'
export const REMOVE_AVAILABILITIES_FAILED = 'ADMIN_REMOVE_AVAILABILITY_FAILED'

export const FETCH_APPOINTMENT_INIT = 'ADMIN_FETCH_APPOINTMENT_INIT'
export const FETCH_APPOINTMENT_SUCCEDED = 'ADMIN_FETCH_APPOINTMENT_SUCCEDED'
export const FETCH_APPOINTMENT_FAILED = 'ADMIN_FETCH_APPOINTMENT_FAILED'

export const UPLOAD_IMAGE_INIT = 'ADMIN_UPLOAD_IMAGE_INIT'
export const UPLOAD_IMAGE_SUCCEDED = 'ADMIN_UPLOAD_IMAGE_SUCCEDED'
export const UPLOAD_IMAGE_FAILED = 'ADMIN_UPLOAD_IMAGE_FAILED'

export const DUPLICATE_APPOINTMENT_INIT = 'ADMIN_DUPLICATE_APPOINTMENT_INIT'
export const DUPLICATE_APPOINTMENT_SUCCEDED = 'ADMIN_DUPLICATE_APPOINTMENT_SUCCEDED'
export const DUPLICATE_APPOINTMENT_FAILED = 'ADMIN_DUPLICATE_APPOINTMENT_FAILED'

export const DELETE_APPOINTMENT_INIT = 'ADMIN_DELETE_APPOINTMENT_INIT'
export const DELETE_APPOINTMENT_SUCCEDED = 'ADMIN_DELETE_APPOINTMENT_SUCCEDED'
export const DELETE_APPOINTMENT_FAILED = 'ADMIN_DELETE_APPOINTMENT_FAILED'

export const ADD_PARTICIPATION_INIT = "ADMIN_ADD_PARTICIPATION_INIT"
export const ADD_PARTICIPATION_SUCCEDED = "ADMIN_ADD_PARTICIPATION_SUCCEDED"
export const ADD_PARTICIPATION_FAILED = "ADMIN_ADD_PARTICIPATION_FAILED"

export const REMOVE_PARTICIPATION_INIT = "ADMIN_REMOVE_PARTICIPATION_INIT"
export const REMOVE_PARTICIPATION_SUCCEDED = "ADMIN_REMOVE_PARTICIPATION_SUCCEDED"
export const REMOVE_PARTICIPATION_FAILED = "ADMIN_REMOVE_PARTICIPATION_FAILED"

export const CREATE_APPOINTMENT_INIT = 'ADMIN_CREATE_APPOINTMENT_INIT'
export const CREATE_APPOINTMENT_SUCCEDED = 'ADMIN_CREATE_APPOINTMENT_SUCCEDED'
export const CREATE_APPOINTMENT_FAILED = 'ADMIN_CREATE_APPOINTMENT_FAILED'

export const UPDATE_APPOINTMENT_INIT = 'ADMIN_UPDATE_APPOINTMENT_INIT'
export const UPDATE_APPOINTMENT_SUCCEDED = 'ADMIN_UPDATE_APPOINTMENT_SUCCEDED'
export const UPDATE_APPOINTMENT_FAILED = 'ADMIN_UPDATE_APPOINTMENT_FAILED'

export const DOWNLOAD_APPOINTMENTS_INIT = 'DOWNLOAD_APPOINTMENTS_INIT'
export const DOWNLOAD_APPOINTMENTS_SUCCEDED = 'DOWNLOAD_APPOINTMENTS_SUCCEDED'
export const DOWNLOAD_APPOINTMENTS_FAILED = 'DOWNLOAD_APPOINTMENTS_FAILED'

export const COPY_APPOINTMENT = "ADMIN_COPY_APPOINTMENT"

export const APPOINTMENTS_SORT = "ADMIN_APPOINTMENTS_SORT"
export const CLEAR_APPOINTMENT_STORE = "ADMIN_CLEAR_APPOINTMENT_STORE"

export const ENDPOINT_APPOINTMENTS = "/api/appointments"
export const ENDPOINT_PARTICIPATION = "/api/inscriptions"
export const ENDPOINT_APPOINTMENT = "/api/appointments/:param"
export const ENDPOINT_DOWNLOAD_APPOINTMENTS = "/api/appointments/download"

export const ENDPOINT_AVAILABILITIES = "/api/availabilities"
export const ENDPOINT_MULTIPLE_AVAILABILITIES = "/api/availabilities/multiple"
export const ENDPOINT_ALL_USERS = "/api/users/all"

export const ENDPOINT_USERS = "/api/users"

export const FETCH_USERS_INIT = 'USER_FETCH_USERS_INIT'
export const FETCH_USERS_FOR_APPOINTMENTS_SUCCEDED = 'FETCH_USERS_FOR_APPOINTMENTS_SUCCEDED'
export const FETCH_USERS_FAILED = 'USER_FETCH_USERS_FAILED'