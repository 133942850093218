export const FETCH_STATS_INIT = 'ADMIN_FETCH_STATS_INIT'
export const FETCH_STATS_SUCCEDED = 'ADMIN_FETCH_STATS_SUCCEDED'
export const FETCH_STATS_FAILED = 'ADMIN_FETCH_STATS_FAILED'

export const COMPARE_STATS_INIT = 'ADMIN_COMPARE_STATS_INIT'
export const COMPARE_STATS_SUCCEDED = 'ADMIN_COMPARE_STATS_SUCCEDED'
export const COMPARE_STATS_FAILED = 'ADMIN_COMPARE_STATS_FAILED'

export const DOWNLOAD_STATS_INIT = 'ADMIN_DOWNLOAD_STATS_INIT'
export const DOWNLOAD_STATS_SUCCEDED = 'ADMIN_DOWNLOAD_STATS_SUCCEDED'
export const DOWNLOAD_STATS_FAILED = 'ADMIN_DOWNLOAD_STATS_FAILED'

export const ENDPOINT_STATS = "/api/stats"
export const ENDPOINT_DOWNLOAD_STATS = "/api/stats/download"
