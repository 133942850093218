import React from 'react'


import './styles/page-loader.scss'


const PageLoader = () => {

  return (
    <div className="lr-page-loader">
      <div className="lr-page-loader__box">
        <div className="lr-page-loader__box__line lr-page-loader__box__line__one"></div>
        <div className="lr-page-loader__box__line lr-page-loader__box__line__two"></div>
        <div className="lr-page-loader__box__line lr-page-loader__box__line__three"></div>
        <div className="lr-page-loader__box__line lr-page-loader__box__line__four"></div>
      </div>
    </div>
  )
}

export default PageLoader
