import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { Editor } from "@tinymce/tinymce-react";

import { updateContent } from "../store/actions";

function RichTextContentInput(props) {
  const { label, contentKey, items, updateContent, height = 200 } = props;

  const item = items?.find((item) => item.key === contentKey);

  const editorRef = useRef(null);
  const [dirty, setDirty] = useState(false);
  useEffect(() => setDirty(false), [item?.text]);

  const save = () => {
    if (editorRef.current && dirty) {
      const text = editorRef.current.getContent();
      setDirty(false);
      editorRef.current.setDirty(false);
      updateContent(contentKey, { text });
    }
  };

  useEffect(() => {
    window.addEventListener("save-content", save);

    return () => {
      window.removeEventListener("save-content", save);
    };
  }, [dirty]);

  return (
    <div className="lr-input-textarea form-group">
      <label className="lr-control-label">
        {label}
      </label>
      <Editor
        apiKey="6lbfu0foiwz1p3w6cjvc6d9mzayj8o39pbixdolmo6wtm9vt"
        onInit={(evt, editor) => (editorRef.current = editor)}
        onDirty={() => setDirty(true)}
        initialValue={item?.text}
        init={{
          height,
          menubar: false,
          plugins: ["autolink", "lists", "link"],
          toolbar:
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist | " +
            "removeformat",
        }}
      />
    </div>
  );
}

// Get data from reducers
const mapStateToProps = (state) => ({ ...state.content });

export default connect(mapStateToProps, { updateContent })(
  RichTextContentInput
);
