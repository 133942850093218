import * as ACTIONS from '../constants'

// Initial State
const programInitialState = {
  error: null,
  success: null,
  isLoading: false,
  isLoadingAvailabilities: false,
  availabilities: [],
  users: [],
  items: [],
  total: 0,
  limit: 0,
  page:0,
  pages: 0,
  item: null
}

/**
 * Update program store
 * @param  {} state: programInitialState
 * @param  {} action
 */
const programReducer = (state = programInitialState, action) => {
  const { payload, type } = action
  switch  (type) {

    case ACTIONS.FETCH_APPOINTMENTS_INIT : {
      return { ...state, isLoading: true, items: [], error: null }
    }
    case ACTIONS.FETCH_APPOINTMENTS_SUCCEDED : {
      const { appointments: items, total, limit, page, pages } = payload.data
      return { ...state, items, isLoading: false, total, pages, limit, page, error: null }
    }
    case ACTIONS.FETCH_APPOINTMENTS_FAILED : {
      return { ...state, error: payload, isLoading: false, items: [] }
    }

    case ACTIONS.FETCH_USERS_FOR_APPOINTMENTS_SUCCEDED: {
      const { users } = payload.data
      return { ...state, users, isFetching: false, error: null }
    }

    case ACTIONS.DOWNLOAD_APPOINTMENTS_INIT: {
      return { ...state, isDownloading: true, error: null }
    }
    case ACTIONS.DOWNLOAD_APPOINTMENTS_SUCCEDED: {
      return { ...state, file: payload, isDownloading: false, error: null }
    }
    case ACTIONS.DOWNLOAD_APPOINTMENTS_FAILED: {
      return { ...state, error: payload, isDownloading: false }
    }

    case ACTIONS.FETCH_ALL_USERS_INIT : {
      return { ...state, isLoading: true, users: [], error: null }
    }
    case ACTIONS.FETCH_ALL_USERS_SUCCEDED : {
      return { ...state, users: payload.data, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_ALL_USERS_FAILED : {
      return { ...state, error: payload, isLoading: false, items: [] }
    }

    case ACTIONS.CLEAR_APPOINTMENT_STORE : {
      return { ...state, success: null, item: null, isLoading: false, error: null }
    }

    case ACTIONS.CREATE_APPOINTMENT_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.CREATE_APPOINTMENT_SUCCEDED : {
      return { ...state, item: payload.data, success: true, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_APPOINTMENT_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.UPDATE_APPOINTMENT_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.UPDATE_APPOINTMENT_SUCCEDED : {
      return { ...state, item: payload.data, success: true, isLoading: false, error: null }
    }
    case ACTIONS.UPDATE_APPOINTMENT_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.FETCH_APPOINTMENT_INIT : {
      return { ...state, isLoading: true, item: null, error: null }
    }
    case ACTIONS.FETCH_APPOINTMENT_SUCCEDED : {
      return { ...state, item: payload.data, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_APPOINTMENT_FAILED : {
      return { ...state, error: payload, isLoading: false, item: null }
    }

    case ACTIONS.DELETE_APPOINTMENT_INIT : {
      return { ...state, isLoading: true, item: null, error: null }
    }
    case ACTIONS.DELETE_APPOINTMENT_SUCCEDED : {
      return { ...state, items: state.items.filter((item) => item.id !== payload.data.id), success: true, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_APPOINTMENT_FAILED : {
      return { ...state, error: payload, isLoading: false, item: null }
    }
    
    case ACTIONS.FETCH_AVAILABILITIES_INIT : {
      return { ...state, isLoadingAvailabilities: true, error: null }
    }
    case ACTIONS.FETCH_AVAILABILITIES_SUCCEDED : {
      return { ...state, availabilities: payload.data, isLoadingAvailabilities: false, error: null }
    }
    case ACTIONS.FETCH_AVAILABILITIES_FAILED : {
      return { ...state, error: payload, isLoadingAvailabilities: false }
    }

    case ACTIONS.ADD_AVAILABILITIES_INIT : {
      return { ...state, isUpdating: true, error: null }
    }
    case ACTIONS.ADD_AVAILABILITIES_SUCCEDED : {
      return { ...state, items: state.items.filter((item) => item.id !== payload.data.id), success: true, isUpdating: false, error: null }
    }
    case ACTIONS.ADD_AVAILABILITIES_FAILED : {
      return { ...state, error: payload, isUpdating: false, item: null }
    }

    case ACTIONS.REMOVE_AVAILABILITIES_INIT : {
      return { ...state, isUpdating: true, error: null }
    }
    case ACTIONS.REMOVE_AVAILABILITIES_SUCCEDED : {
      return { ...state, success: true, isUpdating: false, error: null }
    }
    case ACTIONS.REMOVE_AVAILABILITIES_FAILED : {
      return { ...state, error: payload, isUpdating: false, item: null }
    }
    
    default: {
      return state
    }
  }
}

export default programReducer
