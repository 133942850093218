import * as ACTIONS from '../constants'

// Initial State
const programInitialState = {
  error: null,
  isLoading: false,
  isLoadingActivity: false,
  items: [],
  total: 0,
  limit: 0,
  page:0,
  pages: 0,
  item: null
}

/**
 * Update program store
 * @param  {} state: programInitialState
 * @param  {} action
 */
const programReducer = (state = programInitialState, action) => {
  const { payload, type } = action
  switch  (type) {

    case ACTIONS.FETCH_PROGRAMS_INIT : {
      return { ...state, isLoading: true, items: [], item: null, error: null }
    }
    case ACTIONS.FETCH_PROGRAMS_SUCCEDED : {
      const { activities: items, total, limit, page, pages } = payload.data
      return { ...state, items, isLoading: false, total, pages, limit, page, error: null }
    }
    case ACTIONS.FETCH_PROGRAMS_FAILED : {
      return { ...state, error: payload, isLoading: false, items: [], page: 0, pages: 0  }
    }

    case ACTIONS.FETCH_PROGRAM_INIT : {
      return { ...state, isLoadingActivity: true, item: null, error: null }
    }
    case ACTIONS.FETCH_PROGRAM_SUCCEDED : {
      return { ...state, item: payload.data, isLoadingActivity: false, error: null }
    }
    case ACTIONS.FETCH_PROGRAM_FAILED : {
      return { ...state, error: payload, isLoadingActivity: false, item: null }
    }

    case ACTIONS.ADD_PARTICIPATION_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.ADD_PARTICIPATION_SUCCEDED : {
      return { ...state, item: { 
        ...state.item, 
        id: payload.data.activityId || state.item.id,
        reload: Boolean(payload.data.activityId),
        remainingPlaces: state.item.remainingPlaces - payload.data.peopleCount, 
        inscriptions: [ ...state.item.inscriptions, payload.data] }, 
        isLoading: false, error: null 
      }
    }
    case ACTIONS.ADD_PARTICIPATION_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.REMOVE_PARTICIPATION_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.REMOVE_PARTICIPATION_SUCCEDED : {
      return { 
        ...state, 
        item: { 
          ...state.item, 
          remainingPlaces: state.item.remainingPlaces + payload.data.peopleCount,
          inscriptions: state.item.inscriptions.filter((inscrit) => inscrit.id !== payload.data.id) 
        }, 
        isLoading: false, 
        error: null 
      }
    }
    case ACTIONS.REMOVE_PARTICIPATION_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }
    
    default: {
      return state
    }
  }
}

export default programReducer
