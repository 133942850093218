import * as ACTIONS from "../constants";

// Initial State
const serviceInitialState = {
  error: null,
  success: false,
  isLoading: false,
  isUploading: false,
  isDownloading: false,
  file: null,
  image: null,
  items: [],
  total: 0,
  limit: 0,
  page: 0,
  pages: 0,
  item: null,
  resetFilters: false,
};

/**
 * Update team members store
 * @param  {} state: serviceInitialState
 * @param  {} action
 */
const servicesReducer = (state = serviceInitialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.FETCH_SERVICES_INIT: {
      return { ...state, items: [], error: null };
    }
    case ACTIONS.FETCH_SERVICES_SUCCEEDED: {
      return {
        ...state,
        items: payload.data,
        error: null,
      };
    }
    case ACTIONS.FETCH_SERVICES_FAILED: {
      return { ...state, error: payload, items: [] };
    }

    case ACTIONS.CREATE_SERVICE_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.CREATE_SERVICE_SUCCEEDED: {
      return {
        ...state,
        items: [...state.items, payload.data],
        success: true,
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.CREATE_SERVICE_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.UPDATE_SERVICE_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.UPDATE_SERVICE_SUCCEEDED: {
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === payload.data.id ? payload.data : item
        ),
        success: true,
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.UPDATE_SERVICE_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.REMOVE_SERVICE_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.REMOVE_SERVICE_SUCCEEDED: {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload.data.id),
        success: true,
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.REMOVE_SERVICE_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.UPLOAD_IMAGE_INIT: {
      return { ...state, isUploading: true };
    }
    case ACTIONS.UPLOAD_IMAGE_SUCCEEDED: {
      const { file: image } = payload.data;
      return { ...state, isUploading: false, image };
    }
    case ACTIONS.UPLOAD_IMAGE_RESET: {
      return { ...state, isUploading: false, image: null };
    }
    case ACTIONS.UPLOAD_IMAGE_FAILED: {
      return { ...state, isUploading: false };
    }

    default: {
      return state;
    }
  }
};

export default servicesReducer;
