import * as ACTIONS from '../constants'

// Initial State
const profileInitialState = {
  error: null,
  uploadComplete: false,
  success: false,
  isLoading: false,
  currentAdmin: null,
}

/**
 * Update profile store
 * @param  {} state: profileInitialState
 * @param  {} action
 */
const profileReducer = (state = profileInitialState, action) => {
  const { payload, type } = action
  switch  (type) {

    case ACTIONS.CLEAR_ADMIN_PROFILE_STORE : {
      return { ...state, error: null, success: false, uploadComplete: false }
    }

    case ACTIONS.FETCH_CURRENT_ADMIN_INIT : {
      return { ...state, isLoading: true, error: null, currentAdmin: null }
    }
    case ACTIONS.FETCH_CURRENT_ADMIN_SUCCEDED : {
      const { data: currentAdmin } = payload
      return { ...state, currentAdmin, isLoading: false }
    }
    case ACTIONS.FETCH_CURRENT_ADMIN_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.UPDATE_ADMIN_PROFILE_INIT : {
      return { ...state, isLoading: true, error: null, success: false, uploadComplete: false }
    }
    case ACTIONS.UPDATE_ADMIN_PROFILE_SUCCEDED : {
      const { success, data: currentAdmin } = payload
      return { ...state, success, currentAdmin, isLoading: false }
    }
    case ACTIONS.UPDATE_ADMIN_PROFILE_FAILED : {
      
      return { ...state, error: payload, success: false, isLoading: false }
    }

    case ACTIONS.UPLOAD_ADMIN_AVATAR_INIT : {
      return { ...state, error: null, success: false, uploadComplete: false }
    }
    case ACTIONS.UPLOAD_ADMIN_AVATAR_SUCCEDED : {
      const { file } = payload.data
      return { ...state, currentAdmin: { ...state.currentAdmin, avatar: file }, uploadComplete: true, isLoading: false }
    }
    case ACTIONS.UPLOAD_ADMIN_AVATAR_FAILED : {
      return { ...state, error: { ...payload, uploadFailed: true }, uploadComplete: true, isLoading: false }
    }

    case ACTIONS.CLEAR_AUTH_STORE : {
      return profileInitialState
    }

    default: {
      return state
    }
  }
}

export default profileReducer
