import { lazy } from "react";

// App Imports
import { ROLES } from "../../../setup/constants";
import TeamMembers from "../../../modules/admin/team-members/TeamMembers";
import FaqAdmin from "../../../modules/admin/faq/FaqAdmin";
import ServicesAdmin from "../../../modules/admin/services/ServicesAdmin";
import ServiceAdmin from "../../../modules/admin/services/ServiceAdmin";
const Home = lazy(() => import("../../../modules/admin/content/Home"));

// User content routes
export const homeContent = {
  path: "/content/home",
  name: 'homeContent',
  component: Home,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
};


// User content routes
export const teamMembers = {
  path: "/content/team",
  name: 'teamMembers',
  component: TeamMembers,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
};

// FAQ admin route
export const faqAdmin = {
  path: "/content/faq",
  name: 'faqAdmin',
  component: FaqAdmin,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
};

// Services admin route
export const servicesAdmin = {
  path: "/content/services",
  name: 'servicesAdmin',
  component: ServicesAdmin,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
};

export const serviceAdmin = {
  path: "/content/services/:param",
  name: 'servicesAdmin',
  component: ServiceAdmin,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
};