export const FETCH_ACTUALITIES_INIT = "ACTUALITY_FETCH_ACTUALITIES_INIT";
export const FETCH_ACTUALITIES_SUCCEDED = "ACTUALITY_FETCH_ACTUALITIES_SUCCEDED";
export const FETCH_ACTUALITIES_FAILED = "ACTUALITY_FETCH_ACTUALITIES_FAILED";

export const FETCH_ACTUALITIE_INIT = "ACTUALITY_FETCH_ACTUALITIE_INIT";
export const FETCH_ACTUALITIE_SUCCEDED = "ACTUALITY_FETCH_ACTUALITIE_SUCCEDED";
export const FETCH_ACTUALITIE_FAILED = "ACTUALITY_FETCH_ACTUALITIE_FAILED";

export const ADD_ACTUALITY_INIT = "ACTUALITY_ADD_ACTUALITY_INIT";
export const ADD_ACTUALITY_SUCCEDED = "ACTUALITY_ADD_ACTUALITY_SUCCEDED";
export const ADD_ACTUALITY_FAILED = "ACTUALITY_ADD_ACTUALITY_FAILED";

export const UPDATE_ACTUALITY_INIT = "ACTUALITY_UPDATE_ACTUALITY_INIT";
export const UPDATE_ACTUALITY_SUCCEDED = "ACTUALITY_UPDATE_ACTUALITY_SUCCEDED";
export const UPDATE_ACTUALITY_FAILED = "ACTUALITY_UPDATE_ACTUALITY_FAILED";

export const DELETE_ACTUALITY_INIT = "ACTUALITY_DELETE_ACTUALITY_INIT";
export const DELETE_ACTUALITY_SUCCEDED = "ACTUALITY_DELETE_ACTUALITY_SUCCEDED";
export const DELETE_ACTUALITY_FAILED = "ACTUALITY_DELETE_ACTUALITY_FAILED";

export const UPLOAD_ACTUALITIES_IMAGE_INIT = "UPLOAD_ACTUALITIES_IMAGE_INIT";
export const UPLOAD_ACTUALITIES_IMAGE_SUCCEDED = "UPLOAD_ACTUALITIES_IMAGE_SUCCEDED";
export const UPLOAD_ACTUALITIES_IMAGE_RESET = "UPLOAD_ACTUALITIES_IMAGE_RESET";
export const UPLOAD_ACTUALITIES_IMAGE_FAILED = "UPLOAD_ACTUALITIES_IMAGE_FAILED";


export const ENDPOINT_ACTUALITIES = "/api/news";
export const ENDPOINT_ACTUALITIE = "/api/news/:param";
export const ENDPOINT_UPLOAD = "/api/upload";