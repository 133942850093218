export const ACTIVITY_HOURS = new Array(24)
  .fill(0)
  .reduce((hours, value, index) => {
    hours.push({
      label: `${index > 9 ? index : `0${index}`}h`,
      value: `${index > 9 ? index : `0${index}`}:00:00`,
    });
    hours.push({
      label: `${index > 9 ? index : `0${index}`}h30`,
      value: `${index > 9 ? index : `0${index}`}:30:00`,
    });

    return hours;
  }, []);

export const ACTIVITY_DRAFT_STATUS = "draft";

export const ACTIVITY_STATUS_NAMES = {
  ongoing: { label: "En cours", color: "primary" },
  upcoming: { label: "A venir", color: "secondary" },
  inprogress: { label: "En cours", color: "primary" },
  done: { label: "Terminé", color: "blue" },
  [ACTIVITY_DRAFT_STATUS]: { label: "Brouillon", color: "gray" },
};

export const ACTIVITY_STATUS = Object.keys(ACTIVITY_STATUS_NAMES).map(
  (key) => ({ label: ACTIVITY_STATUS_NAMES[key].label, value: key })
);

export const ACTIVITY_LOCATIONS = [
  { label: "En ligne", value: "En ligne" },
  { label: "Sur place", value: "Sur place" },
  { label: "Hors les murs", value: "Hors les murs" },
];

export const CATEGORIES_NAMES = {
  WELL_BEING: "Bien-être",
  CULTURE: "Culture",
  PHYSICAL_ACTIVITY: "Activité physique",
  RENCONTRES: "Rencontres",
  NEW_ARRIVALS: "Nouveautés",
  ADVICE: "Conseil",
};

export const CATEGORIES = Object.keys(CATEGORIES_NAMES).map((key) => ({
  label: CATEGORIES_NAMES[key],
  value: key,
}));

export const CATEGORIES_FILTERS = [
  { value: "", label: "Toutes les activités" },
  ...CATEGORIES,
];

export const PAYMENT_METHODS_LIST = {
  onspot: { userLabel: "Payer sur place", adminLabel: "Paiement sur place" },
  card: {
    userLabel: "Payer par carte bancaire",
    adminLabel: "Paiement par carte bancaire",
  },
};

export const FREE_PAYMENT_METHOD = "free";

export const PAYMENT_METHODS = [{ label: "sur place", value: "onspot" }];
