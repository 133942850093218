// App Imports
import * as programs from './programs'
import * as profile from './profile'
import * as support from './support'
import * as home from './home'
import * as services from './services'
import * as pricing from './pricing'


// User routes
const user = {
  ...programs,
  ...profile,
  ...support,
  ...home,
  ...services,
  ...pricing
}

export default user
