import React from 'react'
import PropTypes from 'prop-types'

import './styles/input-label.scss'


const InputLabel = ({children}) => (
  <label className="lr-control-label">{children}</label>
)

InputLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
    PropTypes.string
  ])
}

export default InputLabel
