import { SAVE_USER_CREDENTIALS, CLEAR_USER_CREDENTIALS, UPDATE_USER_INFO } from './../constants'
import { clearLocalStorageData, updateLocalStorageData, setLocalStorageData } from './../helpers'


export const clearStorage = store => next => action => {
  if (!action || action.type !== CLEAR_USER_CREDENTIALS ){
    return next(action)
  }
  
  clearLocalStorageData()
}


export const setStorage = store => next => action => {
  if (!action || action.type !== SAVE_USER_CREDENTIALS ){
    return next(action)
  }
  
  const { token, user } = action.payload.data

  setLocalStorageData(token, user)
}


export const updateStorage = store => next => action => {
  if (!action || action.type !== UPDATE_USER_INFO ){
    return next(action)
  }
  if (action.payload && action.payload.data){
    updateLocalStorageData(action.payload.data)
  }else {
    const { currentUser } = store.getState().auth 
    updateLocalStorageData(currentUser)
  }
}
