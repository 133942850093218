export const ERROR_CODES = {
  901: {
    code: 901,
    message: 'Route introuvable',
  },
  902: {
    code: 902,
    message: "Type de contenu non accepté",
  },
  1003: {
    code: 1003,
    message: 'Autorisation requise',
  },
  2001: {
    code: 2001,
    message: 'Utilisateur introuvable',
  },
  2002: {
    code: 2002,
    message: 'Cet utilisateur est déjà parrainé',
  },
  2003: {
    code: 2003,
    message: 'Cet utilisateur est déjà affilié',
  },
  2004: {
    code: 2004,
    message: 'Cet L\'utilisateur n\'a pas de portefeuille',
  },
  2005: {
    code: 2005,
    message: 'Cet utilisateur déjà inscrit',
  },
  3001: {
    code: 3001,
    message: 'Activité introuvable',
  },
  3002: {
    code: 3002,
    message: 'Activité déjà pleine',
  },
  4001: {
    code: 4001,
    message: 'Fichier requis',
  },
  5001: {
    code: 5001,
    message: 'Inscription introuvable',
  },
  5002: {
    code: 5002,
    message: 'L\'inscription existe déjà',
  },
  5003: {
    code: 5003,
    message: 'Mauvaise méthode de paiement',
  },
  5004: {
    code: 5004,
    message: 'Inscription non remboursable',
  },
  6001: {
    code: 6001,
    message: 'Pack introuvable',
  },
  7001: {
    code: 7001,
    message: 'Pas assez de crédits',
  },
  7002: {
    code: 7002,
    message: "La source est requise pour le paiement par carte",
  },
  8001: {
    code: 8001,
    message: 'Disponibilité non trouvée',
  },
  8002: {
    code: 8002,
    message: 'La disponibilité existe déjà',
  },
  9001: {
    code: 9001,
    message: "Rendez-vous introuvable",
  },
  10001: {
    code: 10001,
    message: 'Catégorie non trouvée',
  },
  9002: {
    code: 9002,
    message: 'La date du rendez-vous est déjà expirée'
  },
  2006: {
    code: 2006,
    message: 'Cet utilisateur n\'êtes pas encore vérifié',
  }
}

