import * as ACTIONS from "../constants";

// Initial State
const contentInitialState = {
  isLoading: false,
  items: [],
  success: false,
  error: null,
  isUploading: false,
  image: null,
};

/**
 * Update anaymous state
 * @param  {} state: contentInitialState
 * @param  {} action
 */
const contentReducer = (state = contentInitialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.LOAD_CONTENT_INIT: {
      return { ...state, isLoading: true, success: false, codeError: 0 };
    }
    case ACTIONS.LOAD_CONTENT_SUCCEEDED: {
      const { success } = payload;
      return { ...state, items: payload.data, isLoading: false, success };
    }
    case ACTIONS.LOAD_CONTENT_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.UPDATE_CONTENT_INIT: {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.UPDATE_CONTENT_SUCCEEDED: {
      const items = state.items.map(item => (item.key === payload.data.key ? payload.data : item));
      return { ...state, items, success: true, isLoading: false, error: null }
    }
    case ACTIONS.UPDATE_CONTENT_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.UPLOAD_IMAGE_INIT: {
      return { ...state, isUploading: true };
    }
    case ACTIONS.UPLOAD_IMAGE_SUCCEEDED: {
      const { file: image } = payload.data;
      return { ...state, isUploading: false, image };
    }
    case ACTIONS.UPLOAD_IMAGE_RESET: {
      return { ...state, isUploading: false, image: null };
    }
    case ACTIONS.UPLOAD_IMAGE_FAILED: {
      return { ...state, isUploading: false };
    }

    default: {
      return state;
    }
  }
};

export default contentReducer;
