import { CALL_API, HTTP_METHODS } from "../../../../../setup/constants";
import * as ACTIONS from "../constants";

export const fetchFaq = () => {
  return {
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_FAQ_INIT,
        success: ACTIONS.FETCH_FAQ_SUCCEEDED,
        fail: ACTIONS.FETCH_FAQ_FAILED,
      },
      endpoint: ACTIONS.ENDPOINT_FAQ,
      method: HTTP_METHODS.GET,
      auth: true,
    },
  };
};

export const createFaq = (payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CREATE_FAQ_INIT,
      success: ACTIONS.CREATE_FAQ_SUCCEEDED,
      fail: ACTIONS.CREATE_FAQ_FAILED,
    },
    endpoint: ACTIONS.ENDPOINT_FAQ,
    method: HTTP_METHODS.POST,
    auth: true,
  },
});

export const deleteFaq = (id) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.REMOVE_FAQ_INIT,
      success: ACTIONS.REMOVE_FAQ_SUCCEEDED,
      fail: ACTIONS.REMOVE_FAQ_FAILED,
    },
    endpoint: ACTIONS.ENDPOINT_FAQ_ID.replace(":id", id),
    method: HTTP_METHODS.DELETE,
    auth: true,
  },
});

export const uploadImage = (payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.UPLOAD_IMAGE_INIT,
      success: ACTIONS.UPLOAD_IMAGE_SUCCEEDED,
      fail: ACTIONS.UPLOAD_IMAGE_FAILED,
    },
    endpoint: ACTIONS.ENDPOINT_UPLOAD,
    method: HTTP_METHODS.POST,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    auth: true,
  },
});

export const resetImage = () => ({
  type: ACTIONS.UPLOAD_IMAGE_RESET,
});
