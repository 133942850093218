export const CREATE_TODO_LIST_INIT = 'TODO_LIST_CREATE_TODO_LIST_INIT'
export const CREATE_TODO_LIST_SUCCEDED = 'TODO_LIST_CREATE_TODO_LIST_SUCCEDED'
export const CREATE_TODO_LIST_FAILED = 'TODO_LIST_CREATE_TODO_LIST_FAILED'

export const FETCH_TODO_LISTS_INIT = 'TODO_LIST_FETCH_TODO_LISTS_INIT'
export const FETCH_TODO_LISTS_SUCCEDED = 'TODO_LIST_FETCH_TODO_LISTS_SUCCEDED'
export const FETCH_TODO_LISTS_FAILED = 'TODO_LIST_FETCH_TODO_LISTS_FAILED'

export const DELETE_TODO_LIST_INIT = 'TODO_LIST_DELETE_TODO_LIST_INIT'
export const DELETE_TODO_LIST_SUCCEDED = 'TODO_LIST_DELETE_TODO_LIST_SUCCEDED'
export const DELETE_TODO_LIST_FAILED = 'TODO_LIST_DELETE_TODO_LIST_FAILED'

export const EDIT_TODO_LIST_INIT = 'TODO_LIST_EDIT_TODO_LIST_INIT'
export const EDIT_TODO_LIST_SUCCEDED = 'TODO_LIST_EDIT_TODO_LIST_SUCCEDED'
export const EDIT_TODO_LIST_FAILED = 'TODO_LIST_EDIT_TODO_LIST_FAILED'

export const ENDPOINT_TODO_LIST = "/api/axes/:param"
export const ENDPOINT_TODO_LISTS = "/api/axes"
