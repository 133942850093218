import { compose } from "redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import makeApiCall from "./../middleware/api";
import {
  setStorage,
  clearStorage,
  updateStorage,
} from "./../middleware/session";

import rootReducer from "./reducers";

// REDUX_DEVTOOLS_EXTENSION
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default (preloadedState = []) =>
  createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(thunk),
      applyMiddleware(makeApiCall, setStorage, clearStorage, updateStorage)
    )
  );
