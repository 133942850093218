import React from 'react'
import PropTypes from 'prop-types'

import { Button as BaseButton } from 'reactstrap'

import './styles/button.scss'

const Button  = (props) => (
  <div className="lr-button">
    <BaseButton { ...props }>
      { props.children }
    </BaseButton>
  </div>
)

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
    PropTypes.string
  ])
}

export default Button
