import { lazy } from 'react'

// App Imports
const Login = lazy(() => import('../../../modules/common/user-auth/Login'))
const Logout = lazy(() => import('../../../modules/common/user-auth/Logout'))
const ForgotPassword = lazy(() => import('../../../modules/common/user-auth/ForgotPassword'))
const Signup = lazy(() => import('../../../modules/common/user-auth/Signup'))
const ChangePassword = lazy(() => import('../../../modules/common/user-auth/ChangePassword'))

// User - auth routes
export const signup = {
  path: '/inscription',
  component: Signup
}

export const completeSignup = {
  path: '/inscription/:param',
  component: Signup
}

export const changePassword = {
  path: '/reinitialiser/:param',
  component: ChangePassword
}

export const forgotPassword = {
  component: ForgotPassword
}


export const logout = {
  component: Logout
}

export const login = {
  component: Login
}
