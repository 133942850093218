export const ADD_APPOINTMENT_INIT = "ADMIN_ADD_APPOINTMENT_INIT"
export const ADD_APPOINTMENT_SUCCEDED = "ADMIN_ADD_APPOINTMENT_SUCCEDED"
export const ADD_APPOINTMENT_FAILED = "ADMIN_ADD_APPOINTMENT_FAILED"

export const REMOVE_APPOINTMENT_INIT = "ADMIN_REMOVE_APPOINTMENT_INIT"
export const REMOVE_APPOINTMENT_SUCCEDED = "ADMIN_REMOVE_APPOINTMENT_SUCCEDED"
export const REMOVE_APPOINTMENT_FAILED = "ADMIN_REMOVE_APPOINTMENT_FAILED"

export const FETCH_AVAILABILITIES_INIT = "ADMIN_FETCH_AVAILABILITIES_INIT"
export const FETCH_AVAILABILITIES_SUCCEDED = "ADMIN_FETCH_AVAILABILITIES_SUCCEDED"
export const FETCH_AVAILABILITIES_FAILED = "ADMIN_FETCH_AVAILABILITIES_FAILED"

export const ENDPOINT_SUPPORTS = "/api/supports"
export const ENDPOINT_APPOINTMENTS = "/api/appointments"
export const ENDPOINT_APPOINTMENT = "/api/appointments/:param"
export const ENDPOINT_AVAILABILITIES = "/api/availabilities"
export const ENDPOINT_SUPPORT = "/api/supports/:param"
