import * as ACTIONS from '../constants'

// Initial State
const todoListInitialState = {
  error: null,
  success: false,
  isLoadingOnAdd: false,
  isLoading: false,
  items: [],
  item: null
}

/**
 * Update todoList store
 * @param  {} state: todoListInitialState
 * @param  {} action
 */
const todoListReducer = (state = todoListInitialState, action) => {
  const { payload, type } = action
  switch  (type) {

    case ACTIONS.FETCH_TODO_LISTS_INIT : {
      return { ...state, isLoading: true, items: [], error: null }
    }
    case ACTIONS.FETCH_TODO_LISTS_SUCCEDED : {
      return { ...state, items: payload.data, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_TODO_LISTS_FAILED : {
      return { ...state, error: payload, isLoading: false, items: [] }
    }

    case ACTIONS.CREATE_TODO_LIST_INIT : {
      return { ...state, isLoadingOnAdd: true, error: null }
    }
    case ACTIONS.CREATE_TODO_LIST_SUCCEDED : {
      return { ...state, items: state.items.concat(payload.data), success: true, isLoadingOnAdd: false, error: null }
    }
    case ACTIONS.CREATE_TODO_LIST_FAILED : {
      return { ...state, error: payload, isLoadingOnAdd: false }
    }

    case ACTIONS.EDIT_TODO_LIST_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.EDIT_TODO_LIST_SUCCEDED : {
      return { ...state, items: state.items.map((item) => item.id === payload.data.id ? payload.data :item), success: true, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_TODO_LIST_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.DELETE_TODO_LIST_INIT : {
      return { ...state, isLoading: true, item: null, success: false, error: null }
    }
    case ACTIONS.DELETE_TODO_LIST_SUCCEDED : {
      return { ...state, items: state.items.filter((item) => item.id !== payload.data.id), success: true, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_TODO_LIST_FAILED : {
      return { ...state, error: payload, isLoading: false, item: null }
    }
    
    default: {
      return state
    }
  }
}

export default todoListReducer
