import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef } from "react";

export default function RichInputField(props) {
  const { label, initialValue, onChange, height = 200 } = props;

  const value = useRef(initialValue);

  const editorRef = useRef(null);

  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <div className="lr-input-textarea form-group">
      <label className="lr-control-label">{label}</label>
      <Editor
        apiKey="6lbfu0foiwz1p3w6cjvc6d9mzayj8o39pbixdolmo6wtm9vt"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={value.current}
        onEditorChange={handleChange}
        init={{
          height,
          menubar: false,
          plugins: ["autolink", "lists", "link"],
          toolbar:
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist | " +
            "removeformat",
        }}
      />
    </div>
  );
}
