import * as ACTIONS from "../constants";
import { loadRole } from "../../../../../setup/helpers";

// initial roles
const roles = loadRole();

// Initial State
const userInitialState = {
  error: null,
  success: false,
  isLoading: false,
  isAuthenticated: false,
  currentUser: null,
  user: null,
  accessToken: null,
  ...roles,
};

// parse user birthdate
function parseDate(date) {
  let _date;
  if (typeof date === "string") {
    _date = new Date(date);
  } else {
    _date = date;
  }
  const day =
    _date.getDate().toString().length === 1
      ? `0${_date.getDate()}`
      : _date.getDate();
  const month = (_date.getMonth() + 1).toString();
  const year = _date.getFullYear();

  return { day, month, year };
}
/**
 * Update user auth state
 * @param  {} state: userInitialState
 * @param  {} action
 */
const authReducer = (state = userInitialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_AUTH_STORE: {
      return { ...state, ...userInitialState };
    }

    case ACTIONS.LOGIN_ACCOUNT_INIT: {
      return {
        ...state,
        isLoading: true,
        currentUser: null,
        accessToken: null,
        error: null,
      };
    }
    case ACTIONS.LOGIN_ACCOUNT_SUCCEDED: {
      const { token: accessToken, user: currentUser } = payload.data;
      const roles = loadRole(currentUser.role);
      return {
        ...state,
        currentUser,
        accessToken,
        ...roles,
        isAuthenticated: true,
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.LOGIN_ACCOUNT_FAILED: {
      return {
        ...state,
        error: payload,
        isLoading: false,
        currentUser: null,
        accessToken: null,
      };
    }

    case ACTIONS.EDIT_ACCOUNT_PASSWORD_INIT: {
      return {
        ...state,
        isLoading: true,
        currentUser: null,
        accessToken: null,
        error: null,
      };
    }
    case ACTIONS.EDIT_ACCOUNT_PASSWORD_SUCCEDED: {
      const { token: accessToken, user: currentUser } = payload.data;
      const roles = loadRole(currentUser.role);
      return {
        ...state,
        currentUser,
        accessToken,
        ...roles,
        isAuthenticated: true,
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_ACCOUNT_PASSWORD_FAILED: {
      return {
        ...state,
        error: payload,
        isLoading: false,
        currentUser: null,
        accessToken: null,
      };
    }

    case ACTIONS.CREATE_ACCOUNT_INIT: {
      return {
        ...state,
        isLoading: true,
        currentUser: null,
        accessToken: null,
        error: null,
      };
    }
    case ACTIONS.CREATE_ACCOUNT_SUCCEDED: {
      const { token: accessToken, user: currentUser } = payload.data;
      const roles = loadRole(currentUser.role);
      return {
        ...state,
        currentUser,
        accessToken,
        ...roles,
        isAuthenticated: true,
        user: null,
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.CREATE_ACCOUNT_FAILED: {
      return {
        ...state,
        error: payload,
        isLoading: false,
        currentUser: null,
        accessToken: null,
      };
    }

    case ACTIONS.FETCH_ACCOUNT_INIT: {
      return {
        ...state,
        isLoading: true,
        user: null,
        currentUser: null,
        accessToken: null,
        error: null,
      };
    }
    case ACTIONS.FETCH_ACCOUNT_SUCCEDED: {
      payload.data.birthdate = parseDate(payload.data.birthdate);
      return { ...state, user: payload.data, isLoading: false };
    }
    case ACTIONS.FETCH_ACCOUNT_FAILED: {
      return { ...state, error: payload, isLoading: false, user: null };
    }

    case ACTIONS.RESET_ACCOUNT_INIT: {
      return {
        ...state,
        isLoading: true,
        user: null,
        currentUser: null,
        accessToken: null,
        error: null,
      };
    }
    case ACTIONS.RESET_ACCOUNT_SUCCEDED: {
      return { ...state, success: true, isLoading: false };
    }
    case ACTIONS.RESET_ACCOUNT_FAILED: {
      return { ...state, error: payload, isLoading: false, user: null };
    }

    case ACTIONS.UPDATE_PROFILE_SUCCEDED: {
      const { data: currentUser } = payload;
      return { ...state, currentUser };
    }

    case ACTIONS.ADD_APPOINTMENT_SUCCEDED: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          appointments: [...state.currentUser.appointments, payload.data],
        },
      };
    }

    case ACTIONS.REMOVE_APPOINTMENT_SUCCEDED: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          appointments: state.currentUser.appointments.filter(
            (appointment) => appointment.id !== payload.data.id
          ),
        },
      };
    }

    case ACTIONS.SUBSCRIPTION_SUCCEDED: {
      return {
        ...state,
        currentUser: { ...state.currentUser, subscribed: true },
      };
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
