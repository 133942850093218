import * as ACTIONS from '../constants'

// Initial State
const programInitialState = {
  error: null,
  success: false,
  isLoading: false,
  isLoadingActivities: false,
  isUploading: false,
  isDownloading: false,
  file: null,
  image: null,
  items: [],
  total: 0,
  limit: 0,
  page: 0,
  pages: 0,
  item: null,
  resetFilters: false
}

/**
 * Update program store
 * @param  {} state: programInitialState
 * @param  {} action
 */
const programReducer = (state = programInitialState, action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.FETCH_PROGRAMS_INIT: {
      return { ...state, isLoadingActivities: true, items: [], error: null }
    }
    case ACTIONS.FETCH_PROGRAMS_SUCCEDED: {
      const { activities: items, total, limit, page, pages } = payload.data
      return { ...state, items, isLoadingActivities: false, total, pages, limit, page, error: null }
    }
    case ACTIONS.FETCH_PROGRAMS_FAILED: {
      return { ...state, error: payload, isLoadingActivities: false, items: [] }
    }

    case ACTIONS.DOWNLOAD_PROGRAMS_INIT: {
      return { ...state, isDownloading: true, error: null }
    }
    case ACTIONS.DOWNLOAD_PROGRAMS_SUCCEDED: {
      return { ...state, file: payload, isDownloading: false, error: null }
    }
    case ACTIONS.DOWNLOAD_PROGRAMS_FAILED: {
      return { ...state, error: payload, isDownloading: false }
    }

    case ACTIONS.CLEAR_PROGRAM_STORE: {
      return { ...state, success: false, item: null, isLoading: false, error: null }
    }
    case ACTIONS.RESET_FILTERS: {
      return { ...state, resetFilters: payload }
    }

    case ACTIONS.COPY_PROGRAM: {
      const { status, date, ...item } = payload
      return { ...state, item }
    }

    case ACTIONS.UPLOAD_IMAGE_INIT: {
      return { ...state, isUploading: true }
    }
    case ACTIONS.UPLOAD_IMAGE_SUCCEDED: {
      const { file: image } = payload.data
      return { ...state, isUploading: false, image }
    }
    case ACTIONS.UPLOAD_IMAGE_RESET: {
      return { ...state, isUploading: false, image: null }
    }
    case ACTIONS.UPLOAD_IMAGE_FAILED: {
      return { ...state, isUploading: false }
    }

    case ACTIONS.CREATE_PROGRAM_INIT: {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.CREATE_PROGRAM_SUCCEDED: {
      return { ...state, item: payload.data, success: true, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_PROGRAM_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.UPDATE_PROGRAM_INIT: {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.UPDATE_PROGRAM_SUCCEDED: {
      return { ...state, item: payload.data, success: true, isLoading: false, error: null }
    }
    case ACTIONS.UPDATE_PROGRAM_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.FETCH_PROGRAM_INIT: {
      return { ...state, isLoading: true, item: null, error: null }
    }
    case ACTIONS.FETCH_PROGRAM_SUCCEDED: {
      const item = payload.data
      item.inscriptions = item.inscriptions.reduce((inscriptions, inscription) => {
        inscription.user = item.users.filter((user) => user.id === inscription.userId)[0] || null
        inscriptions.push(inscription)
        return inscriptions
      }, [])
      return { ...state, item, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_PROGRAM_FAILED: {
      return { ...state, error: payload, isLoading: false, item: null }
    }


    case ACTIONS.DUPLICATE_PROGRAM_INIT: {
      return { ...state, isLoading: true, item: null, error: null }
    }
    case ACTIONS.DUPLICATE_PROGRAM_SUCCEDED: {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.DUPLICATE_PROGRAM_FAILED: {
      return { ...state, error: payload, isLoading: false, item: null }
    }

    case ACTIONS.DELETE_PROGRAM_INIT: {
      return { ...state, isLoading: true, item: null, success: false, error: null }
    }
    case ACTIONS.DELETE_PROGRAM_SUCCEDED: {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_PROGRAM_FAILED: {
      return { ...state, error: payload, isLoading: false, item: null }
    }

    case ACTIONS.ADD_PARTICIPATION_INIT: {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.ADD_PARTICIPATION_SUCCEDED: {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.ADD_PARTICIPATION_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.UPDATE_PARTICIPATION_INIT: {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.UPADTE_PARTICIPATION_SUCCEDED: {
      return {
        ...state,
        item: {
          ...state.item,
          inscriptions: state.item.inscriptions.map((inscrit) => inscrit.id === payload.data.id ? { ...inscrit, ...payload.data } : inscrit)
        },
        isLoading: false,
        error: null
      }
    }
    case ACTIONS.UPDATE_PARTICIPATION_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }
    case ACTIONS.REMOVE_PARTICIPATION_ADMIN_INIT: {
      return { ...state, isActivityLoading: true, error: null }
    }
    case ACTIONS.REMOVE_PARTICIPATION_ADMIN_SUCCEDED : {
      return { 
        ...state, 
        item: { 
          ...state.item, 
          remainingPlaces: state.item.remainingPlaces + payload.data.peopleCount,
          inscriptions: state.item.inscriptions.filter((inscription) => inscription.id !== payload.data.id) 
        }, 
        isActivityLoading: false, 
        error: null 
      }
    }
    case ACTIONS.REMOVE_PARTICIPATION_ADMIN_FAILED: {
      return { ...state, error: payload, isActivityLoading: false }
    }

    default: {
      return state
    }
  }
}

export default programReducer
