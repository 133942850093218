export const FETCH_PROGRAMS_INIT = "PROGRAM_FETCH_PROGRAMS_INIT";
export const FETCH_PROGRAMS_SUCCEDED = "PROGRAM_FETCH_PROGRAMS_SUCCEDED";
export const FETCH_PROGRAMS_FAILED = "PROGRAM_FETCH_PROGRAMS_FAILED";

export const DOWNLOAD_PROGRAMS_INIT = "PROGRAM_DOWNLOAD_PROGRAMS_INIT";
export const DOWNLOAD_PROGRAMS_SUCCEDED = "PROGRAM_DOWNLOAD_PROGRAMS_SUCCEDED";
export const DOWNLOAD_PROGRAMS_FAILED = "PROGRAM_DOWNLOAD_PROGRAMS_FAILED";

export const FETCH_PROGRAM_INIT = "PROGRAM_FETCH_PROGRAM_INIT";
export const FETCH_PROGRAM_SUCCEDED = "PROGRAM_FETCH_PROGRAM_SUCCEDED";
export const FETCH_PROGRAM_FAILED = "PROGRAM_FETCH_PROGRAM_FAILED";

export const UPLOAD_IMAGE_INIT = "PROGRAM_UPLOAD_IMAGE_INIT";
export const UPLOAD_IMAGE_SUCCEDED = "PROGRAM_UPLOAD_IMAGE_SUCCEDED";
export const UPLOAD_IMAGE_RESET = "PROGRAM_UPLOAD_IMAGE_RESET";
export const UPLOAD_IMAGE_FAILED = "PROGRAM_UPLOAD_IMAGE_FAILED";

export const DUPLICATE_PROGRAM_INIT = "PROGRAM_DUPLICATE_PROGRAM_INIT";
export const DUPLICATE_PROGRAM_SUCCEDED = "PROGRAM_DUPLICATE_PROGRAM_SUCCEDED";
export const DUPLICATE_PROGRAM_FAILED = "PROGRAM_DUPLICATE_PROGRAM_FAILED";

export const DELETE_PROGRAM_INIT = "PROGRAM_DELETE_PROGRAM_INIT";
export const DELETE_PROGRAM_SUCCEDED = "PROGRAM_DELETE_PROGRAM_SUCCEDED";
export const DELETE_PROGRAM_FAILED = "PROGRAM_DELETE_PROGRAM_FAILED";

export const ADD_PARTICIPATION_INIT = "PROGRAM_ADD_PARTICIPATION_INIT";
export const ADD_PARTICIPATION_SUCCEDED = "PROGRAM_ADD_PARTICIPATION_SUCCEDED";
export const ADD_PARTICIPATION_FAILED = "PROGRAM_ADD_PARTICIPATION_FAILED";

export const UPDATE_PARTICIPATION_INIT = "PROGRAM_UPDATE_PARTICIPATION_INIT";
export const UPADTE_PARTICIPATION_SUCCEDED =
  "PROGRAM_UPDATE_PARTICIPATION_SUCCEDED";
export const UPDATE_PARTICIPATION_FAILED =
  "PROGRAM_UPDATE_PARTICIPATION_FAILED";

export const CREATE_PROGRAM_INIT = "PROGRAM_CREATE_PROGRAM_INIT";
export const CREATE_PROGRAM_SUCCEDED = "PROGRAM_CREATE_PROGRAM_SUCCEDED";
export const CREATE_PROGRAM_FAILED = "PROGRAM_CREATE_PROGRAM_FAILED";

export const UPDATE_PROGRAM_INIT = "PROGRAM_UPDATE_PROGRAM_INIT";
export const UPDATE_PROGRAM_SUCCEDED = "PROGRAM_UPDATE_PROGRAM_SUCCEDED";
export const UPDATE_PROGRAM_FAILED = "PROGRAM_UPDATE_PROGRAM_FAILED";

export const REMOVE_PARTICIPATION_ADMIN_INIT = "REMOVE_PARTICIPATION_ADMIN_INIT";
export const REMOVE_PARTICIPATION_ADMIN_SUCCEDED = "REMOVE_PARTICIPATION_ADMIN_SUCCEDED";
export const REMOVE_PARTICIPATION_ADMIN_FAILED = "REMOVE_PARTICIPATION_ADMIN_FAILED";

export const COPY_PROGRAM = "PROGRAM_COPY_PROGRAM";

export const PROGRAMS_SORT = "PROGRAM_PROGRAMS_SORT";
export const RESET_FILTERS = "RESET_FILTERS_BAR";
export const CLEAR_PROGRAM_STORE = "PROGRAM_CLEAR_PROGRAM_STORE";

export const ENDPOINT_PROGRAMS = "/api/activities";
export const ENDPOINT_UPLOAD = "/api/upload";
export const ENDPOINT_PARTICIPATIONS = "/api/inscriptions";
export const ENDPOINT_PARTICIPATION = "/api/inscriptions/:param";
export const ENDPOINT_PROGRAM = "/api/activities/:param";

export const ENDPOINT_DOWNLOAD_PROGRAMS = "/api/activities/download";
