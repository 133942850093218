import { CALL_API, HTTP_METHODS } from "../../../../../setup/constants";
import * as ACTIONS from "../constants";

export const fetchServices = () => {
  return {
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_SERVICES_INIT,
        success: ACTIONS.FETCH_SERVICES_SUCCEEDED,
        fail: ACTIONS.FETCH_SERVICES_FAILED,
      },
      endpoint: ACTIONS.ENDPOINT_SERVICE,
      method: HTTP_METHODS.GET,
      auth: true,
    },
  };
};

export const createService = (payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CREATE_SERVICE_INIT,
      success: ACTIONS.CREATE_SERVICE_SUCCEEDED,
      fail: ACTIONS.CREATE_SERVICE_FAILED,
    },
    endpoint: ACTIONS.ENDPOINT_SERVICE,
    method: HTTP_METHODS.POST,
    auth: true,
  },
});

export const updateService = (id, payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.UPDATE_SERVICE_INIT,
      success: ACTIONS.UPDATE_SERVICE_SUCCEEDED,
      fail: ACTIONS.UPDATE_SERVICE_FAILED,
    },
    endpoint: ACTIONS.ENDPOINT_SERVICE_ID.replace(":id", id),
    method: HTTP_METHODS.PUT,
    auth: true,
  },
});

export const deleteService = (id) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.REMOVE_SERVICE_INIT,
      success: ACTIONS.REMOVE_SERVICE_SUCCEEDED,
      fail: ACTIONS.REMOVE_SERVICE_FAILED,
    },
    endpoint: ACTIONS.ENDPOINT_SERVICE_ID.replace(":id", id),
    method: HTTP_METHODS.DELETE,
    auth: true,
  },
});

export const uploadImage = (payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.UPLOAD_IMAGE_INIT,
      success: ACTIONS.UPLOAD_IMAGE_SUCCEEDED,
      fail: ACTIONS.UPLOAD_IMAGE_FAILED,
    },
    endpoint: ACTIONS.ENDPOINT_UPLOAD,
    method: HTTP_METHODS.POST,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    auth: true,
  },
});

export const resetImage = () => ({
  type: ACTIONS.UPLOAD_IMAGE_RESET,
});
