import React, { Component } from "react";

import { fetchServices, createService, uploadImage } from "./store/actions";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import Button from "../../../ui/Button";
import { dataURItoBlob } from "../../../setup/helpers";

import { XMarkIcon } from "@heroicons/react/20/solid";
import TextContentInput from "../../common/content/ui/TextContentInput";
import RichTextContentInput from "../../common/content/ui/RichTextContentInput";
import ImageContentInput from "../../common/content/ui/ImageContentInput";
import { fetchContent } from "../../common/content/store/actions";
import FixedButton from "../../../ui/FixedButton";
import RichInputField from "../../../ui/RichInputField";
import InputImageField from "../../../ui/InputImageField";
import { Link } from "react-router-dom";
import { times } from "lodash";

class ServicesAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        title: "",
        description: "",
        content: "",
      },
    };
  }

  componentDidMount() {
    this.props.fetchContent();
    this.props.fetchServices();
  }

  handleSubmit(evt) {
    evt.preventDefault();
    window.dispatchEvent(new Event("save-content"));
  }

  addService = () => {
    this.props.createService({
      icon: this.props.image,
      title: this.state.item.title,
      description: this.state.item.description,
      content: this.state.item.content,
    });

    this.setState({
      image: null,
      item: { icon: "", title: "", description: "", content: "" },
    });
  };

  imageChange = (file) => {
    let formData = new FormData();
    formData.append("file", dataURItoBlob(file));
    this.props.uploadImage(formData);
  };

  render() {
    return (
      <Row className="justify-content-center max-w-4xl mx-auto mb-12">
        <Col lg="12">
          <h1 className="display-2 mb-4">Modifier les offres</h1>
          <form onSubmit={this.handleSubmit} className="border p-4 mb-4">
            <TextContentInput contentKey="partners-title" label="Titre" />
            <RichTextContentInput contentKey="partners-text" />
            <ImageContentInput contentKey="partners-image" />

            <h2 className="text-lg font-medium mt-4 mb-3">
              Ils l'ont fait avec nous
            </h2>

            <div className="space-y-4">
              {times(3).map((index) => (
                <div key={index} className="grid grid-cols-2 gap-4">
                  <div>
                    <ImageContentInput
                      contentKey={`partners-${index}-image`}
                      label="Image"
                    />
                    <TextContentInput
                      contentKey={`partners-${index}-title`}
                      label="Titre"
                    />
                  </div>
                  <RichTextContentInput
                    contentKey={`partners-${index}-text`}
                    label="Texte"
                  />
                </div>
              ))}
            </div>

            <FixedButton />
          </form>
          <div className="mb-4 flex flex-wrap gap-4">
            {this.props.items?.map((item) => (
              <Link
                to={`/content/services/${item.id}`}
                key={item.id}
                className="w-72 border rounded p-3 relative mb-3"
              >
                <img src={item.icon} className="w-12 h-auto" />
                <h2 className="mb-1 font-medium">{item.title}</h2>
                <div className="text-sm">{item.description}</div>
              </Link>
            ))}
          </div>
          <div className="border p-4 rounded">
            <div className="mb-3">
              <InputImageField
                name="image"
                input={{
                  value: this.props.image,
                  onChange: this.imageChange,
                }}
                meta={{}}
                placeholder="Extension : jpg, png ou gif"
                label="Icône"
              />
              <div className="flex flex-col">
                <label htmlFor="name">Titre</label>
                <input
                  id="name"
                  name="name"
                  className="border p-2 rounded mb-2"
                  value={this.state.item.title}
                  onChange={(evt) => {
                    const value = evt.target.value;
                    this.setState((prevState) => ({
                      item: {
                        ...prevState.item,
                        title: value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="title">Description</label>
                <textarea
                  id="title"
                  name="title"
                  className="border p-2 rounded mb-2"
                  value={this.state.item.description}
                  onChange={(evt) => {
                    const value = evt.target.value;
                    this.setState((prevState) => ({
                      item: {
                        ...prevState.item,
                        description: value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col">
                <RichInputField
                  initialValue={this.state.item.content}
                  label="Contenu de la page"
                  height={400}
                  onChange={(value) => {
                    this.setState((prevState) => ({
                      item: {
                        ...prevState.item,
                        content: value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <Button
              onClick={this.addService}
              disabled={
                !this.state.item?.title?.length ||
                !this.state.item?.description?.length
              }
            >
              Ajouter une offre
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

// Get data from reducers
const mapStateToProps = (state) => state.services;

export default connect(mapStateToProps, {
  fetchServices,
  createService,
  uploadImage,
  fetchContent,
})(ServicesAdmin);
