import { lazy } from "react";

// App Imports
const Programs = lazy(() => import("../../../modules/user/programs/Programs"));
const Program = lazy(() => import("../../../modules/user/programs/Program"));

// User Programs routes
export const programs = {
  path: "/programmation",
  component: Programs,
  auth: false,
};

// User Programs routes
export const program = {
  path: "/programmation/:param",
  component: Program,
  auth: false,
};
