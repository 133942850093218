export const FETCH_CURRENT_ADMIN_INIT = 'FETCH_CURRENT_USER_INIT'
export const FETCH_CURRENT_ADMIN_SUCCEDED = 'FETCH_CURRENT_USER_SUCCEDED'
export const FETCH_CURRENT_ADMIN_FAILED = 'FETCH_CURRENT_USER_FAILED'

export const CLEAR_ADMIN_PROFILE_STORE = 'CLEAR_ADMIN_PROFILE_STORE'

export const UPDATE_ADMIN_PROFILE_INIT = 'UPDATE_PROFILE_INIT'
export const UPDATE_ADMIN_PROFILE_SUCCEDED = 'UPDATE_PROFILE_SUCCEDED'
export const UPDATE_ADMIN_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED'

export const UPLOAD_ADMIN_AVATAR_INIT = 'UPLOAD_AVATAR_INIT'
export const UPLOAD_ADMIN_AVATAR_SUCCEDED = 'UPLOAD_AVATAR_SUCCEDED'
export const UPLOAD_ADMIN_AVATAR_FAILED = 'UPLOAD_AVATAR_FAILED'

export const CLEAR_AUTH_STORE = "CLEAR_AUTH_STORE"

export const ENDPOINT_CURRENT_ADMIN = "/api/users/me"
export const ENDPOINT_UPLOAD = "/api/upload"
export const ENDPOINT_CHECK_EMAIL = "/api/auth/emails/verify"

