import { CALL_API, HTTP_METHODS } from "../../../../../setup/constants";
import * as ACTIONS from "../constants";

export const fetchTeamPartners = () => {
  return {
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_TEAM_PARTNERS_INIT,
        success: ACTIONS.FETCH_TEAM_PARTNERS_SUCCEEDED,
        fail: ACTIONS.FETCH_TEAM_PARTNERS_FAILED,
      },
      endpoint: ACTIONS.ENDPOINT_TEAM_PARTNERS,
      method: HTTP_METHODS.GET,
      auth: true,
    },
  };
};

export const createTeamPartner = (payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CREATE_TEAM_PARTNER_INIT,
      success: ACTIONS.CREATE_TEAM_PARTNER_SUCCEEDED,
      fail: ACTIONS.CREATE_TEAM_PARTNER_FAILED,
    },
    endpoint: ACTIONS.ENDPOINT_TEAM_PARTNERS,
    method: HTTP_METHODS.POST,
    auth: true,
  },
});

export const deleteTeamPartner = (id) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.REMOVE_TEAM_PARTNER_INIT,
      success: ACTIONS.REMOVE_TEAM_PARTNER_SUCCEEDED,
      fail: ACTIONS.REMOVE_TEAM_PARTNER_FAILED,
    },
    endpoint: ACTIONS.ENDPOINT_TEAM_PARTNER.replace(":id", id),
    method: HTTP_METHODS.DELETE,
    auth: true,
  },
});

export const uploadImage = (payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.UPLOAD_IMAGE_INIT,
      success: ACTIONS.UPLOAD_IMAGE_SUCCEEDED,
      fail: ACTIONS.UPLOAD_IMAGE_FAILED,
    },
    endpoint: ACTIONS.ENDPOINT_UPLOAD,
    method: HTTP_METHODS.POST,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    auth: true,
  },
});

export const resetImage = () => ({
  type: ACTIONS.UPLOAD_IMAGE_RESET,
});
