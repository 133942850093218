import { lazy } from "react";

// App Imports
import { ROLES } from "../../../setup/constants";

const Users = lazy(() => import("../../../modules/admin/user/Users"));
const UserNew = lazy(() => import("../../../modules/admin/user/UserNew"));
const UserFilter = lazy(() => import("../../../modules/admin/user/UserFilter"));
const UserEdit = lazy(() => import("../../../modules/admin/user/UserEdit"));
const Appointments = lazy(() =>
  import("../../../modules/admin/user/Appointments")
);

const Admins = lazy(() => import("../../../modules/admin/user/Admins"));
const AdminNew = lazy(() => import("../../../modules/admin/user/AdminNew"));
const AdminEdit = lazy(() => import("../../../modules/admin/user/AdminEdit"));

// User programs routes
export const userList = {
  path: "/liste-utilisateurs",
  name: "users",
  component: Users,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN],
};

export const adminList = {
  path: "/liste-admin",
  component: Admins,
  auth: true,
  role: ROLES.SUPER_ADMIN,
};

export const userNew = {
  path: "/nouvel-utilisateur",
  component: UserNew,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN],
};

export const userActivities = {
  path: "/modifier/:param/utilisateur/activities",
  component: UserEdit,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN],
};

export const userEdit = {
  path: "/modifier/:param/utilisateur",
  component: UserEdit,
  auth: true,
  role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN],
};

export const userFilter = {
  component: UserFilter,
};

export const adminNew = {
  path: "/nouvel-administrateur",
  component: AdminNew,
  auth: true,
  role: ROLES.SUPER_ADMIN,
};

export const adminEdit = {
  path: "/modifier/:param/administrateur",
  component: AdminEdit,
  auth: true,
  role: ROLES.SUPER_ADMIN,
};

export const userAppointments = {
  path: "/rendez-vous/:param/utilisateur",
  component: Appointments,
  auth: true,
  role: ROLES.GUIDE,
};
