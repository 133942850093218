export const CREATE_USER_INIT = 'USER_CREATE_USER_INIT'
export const CREATE_USER_SUCCEDED = 'USER_CREATE_USER_SUCCEDED'
export const CREATE_USER_FAILED = 'USER_CREATE_USER_FAILED'

export const FETCH_USERS_INIT = 'USER_FETCH_USERS_INIT'
export const FETCH_USERS_SUCCEDED = 'USER_FETCH_USERS_SUCCEDED'
export const FETCH_USERS_FAILED = 'USER_FETCH_USERS_FAILED'

export const SEARCH_USERS_INIT = 'USER_SEARCH_USERS_INIT'
export const SEARCH_USERS_SUCCEDED = 'USER_SEARCH_USERS_SUCCEDED'
export const SEARCH_USERS_FAILED = 'USER_SEARCH_USERS_FAILED'

export const DOWNLOAD_USERS_INIT = 'USER_DOWNLOAD_USERS_INIT'
export const DOWNLOAD_USERS_SUCCEDED = 'USER_DOWNLOAD_USERS_SUCCEDED'
export const DOWNLOAD_USERS_FAILED = 'USER_DOWNLOAD_USERS_FAILED'

export const FETCH_USER_INIT = 'USER_FETCH_USER_INIT'
export const FETCH_USER_SUCCEDED = 'USER_FETCH_USER_SUCCEDED'
export const FETCH_USER_FAILED = 'USER_FETCH_USER_FAILED'

export const DELETE_USER_INIT = 'USER_DELETE_USER_INIT'
export const DELETE_USER_SUCCEDED = 'USER_DELETE_USER_SUCCEDED'
export const DELETE_USER_FAILED = 'USER_DELETE_USER_FAILED'

export const EDIT_USER_INIT = 'USER_EDIT_USER_INIT'
export const EDIT_USER_SUCCEDED = 'USER_EDIT_USER_SUCCEDED'
export const EDIT_USER_FAILED = 'USER_EDIT_USER_FAILED'

export const SET_USER_SUBSCRIPTION_INIT = 'SET_USER_SUBSCRIPTION_INIT'
export const SET_USER_SUBSCRIPTION_SUCCEDED = 'SET_USER_SUBSCRIPTION_SUCCEDED'
export const SET_USER_SUBSCRIPTION_FAILED = 'SET_USER_SUBSCRIPTION_FAILED'

export const FETCH_USER_APPOINTMENTS_INIT = 'USER_FETCH_USER_APPOINTMENTS_INIT'
export const FETCH_USER_APPOINTMENTS_SUCCEDED = 'USER_FETCH_USER_APPOINTMENTS_SUCCEDED'
export const FETCH_USER_APPOINTMENTS_FAILED = 'USER_FETCH_USER_APPOINTMENTS_FAILED'

export const EDIT_USER_APPOINTMENTS_INIT = 'USER_EDIT_USER_APPOINTMENTS_INIT'
export const EDIT_USER_APPOINTMENTS_SUCCEDED = 'USER_EDIT_USER_APPOINTMENTS_SUCCEDED'
export const EDIT_USER_APPOINTMENTS_FAILED = 'USER_EDIT_USER_APPOINTMENTS_FAILED'

export const BUY_PACK_SUCCEDED = 'ADMIN_BUY_PACK_SUCCEDED'

export const ADD_APPOINTMENT_SUCCEDED = "ADMIN_ADD_APPOINTMENT_SUCCEDED"

export const CLEAR_USER_STORE = 'CLEAR_USER_STORE'
export const FLASH_MESSAGES = '@user/FLASH_MESSAGES'

export const ENDPOINT_DOWNLOAD_USERS = "/api/users/download"
export const ENDPOINT_APPOINTMENTS = "/api/appointments"
export const ENDPOINT_SUBESCRIBE = "/api/users/:param/subscribed"
export const ENDPOINT_USERS = "/api/users"
export const ENDPOINT_SEARCH_USERS = "/api/users/search"
export const ENDPOINT_UPLOAD = "/api/upload"
export const ENDPOINT_USER = "/api/users/:param"

export const ENDPOINT_USER_APPOINTMENTS = "/api/users/:param/appointments"

export const FETCH_ACTIVITIES_INIT = 'USER_FETCH_ACTIVITIES_INIT'
export const FETCH_ACTIVITIES_SUCCEDED = 'USER_FETCH_ACTIVITIES_SUCCEDED'
export const FETCH_ACTIVITIES_FAILED = 'USER_FETCH_ACTIVITIES_FAILED'
export const ENDPOINT_FETCH_ACTIVITIES = "/api/users/:id/activities"
export const RESET_USERS_FILTERS = "RESET_USERS_FILTERS_BAR"