import * as ACTIONS from '../constants'

// Initial State
const actualitiesInitialState = {
    error: null,
    success: false,
    addSuccess: false,
    editSuccess: false,
    isLoading: false,
    isDeleteLoading: false,
    items: [],
    image: null,
    limit: 0,
    page: 0,
    pages: 0,
    item: null,
}

/**
 * Update actualities store
 * @param  {} state: actualitiesInitialState
 * @param  {} action
 */
const actualitiesReducer = (state = actualitiesInitialState, action) => {
    const { payload, type } = action
    switch (type) {

        case ACTIONS.FETCH_ACTUALITIES_INIT: {
            return { ...state, isLoading: true, items: [], error: null }
        }
        case ACTIONS.FETCH_ACTUALITIES_SUCCEDED: {
            const data = { ...payload.data }
            delete data.news
            return { ...state, items: payload.data.news, isLoading: false, error: null, success: payload.success, ...data }
        }
        case ACTIONS.FETCH_ACTUALITIES_FAILED: {
            return { ...state, error: payload, isLoading: false, items: [] }
        }

        case ACTIONS.FETCH_ACTUALITIE_INIT: {
            return { ...state, isLoading: true, item: [], error: null }
        }
        case ACTIONS.FETCH_ACTUALITIE_SUCCEDED: {
            const item = { ...payload.data }
            for (let link of payload.data.links) {
                item["links-:" + link.id] = link.url
            }
            return { ...state, item, isLoading: false, error: null, success: payload.success }
        }
        case ACTIONS.FETCH_ACTUALITIE_FAILED: {
            return { ...state, error: payload, isLoading: false, item: [] }
        }

        case ACTIONS.UPLOAD_ACTUALITIES_IMAGE_INIT: {
            return { ...state, isUploading: true }
        }
        case ACTIONS.UPLOAD_ACTUALITIES_IMAGE_SUCCEDED: {
            const { file: image } = payload.data
            return { ...state, isUploading: false, image }
        }
        case ACTIONS.UPLOAD_ACTUALITIES_IMAGE_RESET: {
            return { ...state, isUploading: false, image: null }
        }
        case ACTIONS.UPLOAD_ACTUALITIES_IMAGE_FAILED: {
            return { ...state, isUploading: false }
        }

        case ACTIONS.ADD_ACTUALITY_INIT: {
            return { ...state, isLoading: true, addSuccess: false, error: null }
        }
        case ACTIONS.ADD_ACTUALITY_SUCCEDED: {
            return { ...state, addSuccess: true, isLoading: false, error: null }
        }
        case ACTIONS.ADD_ACTUALITY_FAILED: {
            return { ...state, error: payload, isLoading: false, addSuccess: false }
        }

        case ACTIONS.UPDATE_ACTUALITY_INIT: {
            return { ...state, isLoading: true, error: null, editSuccess: false, image: null }
        }
        case ACTIONS.UPDATE_ACTUALITY_SUCCEDED: {

            const item = { ...payload.data }
            for (let link of payload.data.links) {
                item["links-:" + link.id] = link.url
            }

            return { ...state, item, isLoading: false, error: null, editSuccess: payload.success }
        }
        case ACTIONS.UPDATE_ACTUALITY_FAILED: {
            return { ...state, error: payload, isLoading: false, editSuccess: false }
        }

        case ACTIONS.DELETE_ACTUALITY_INIT: {
            return { ...state, isDeleteLoading: true, deleteSuccess: false, error: null }
        }
        case ACTIONS.DELETE_ACTUALITY_SUCCEDED: {
            return { ...state, deleteSuccess: true, isDeleteLoading: false, error: null }
        }
        case ACTIONS.DELETE_ACTUALITY_FAILED: {
            return { ...state, error: payload, isDeleteLoading: false, deleteSuccess: false }
        }

        default: {
            return state
        }
    }
}

export default actualitiesReducer
