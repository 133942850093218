import React from 'react'
import PropTypes from 'prop-types'

import './styles/input-error.scss'

const InputError = ({ children }) => (
  <div className="lr-danger-msg">
    { children }
  </div>
)

InputError.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
    PropTypes.string
  ]),
}

export default InputError
