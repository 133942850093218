import { lazy } from 'react'

import { ROLES } from './../../constants'

const Pricing = lazy(() => import('./../../../modules/user/pricing/Pricing'))
const MembershipConditions = lazy(() => import('./../../../modules/user/common/membership/MembershipConditions'))


export const pricing = {
  path: '/tarifs',
  component: Pricing,
  auth: false
}

export const membershipConditions = {
  path: '/adhesion-charte',
  component: MembershipConditions,
  auth: true,
  role: ROLES.USER
}
