export const REFER_FRIEND_INIT = 'REFER_FRIEND_INIT'
export const REFER_FRIEND_FAILED = 'REFER_FRIEND_FAILED'
export const REFER_FRIEND_SUCCEDED = 'REFER_FRIEND_SUCCEDED'

export const FETCH_PACKS_LIST_INIT = 'FETCH_PACKS_LIST_INIT'
export const FETCH_PACKS_LIST_SUCCEDED = 'FETCH_PACKS_LIST_SUCCEDED'
export const FETCH_PACKS_LIST_FAILED = 'FETCH_PACKS_LIST_FAILED'

export const CLEAR_PRICING_STORE = 'CLEAR_PRICING_STORE'

export const ENDPOINT_REFER_FRIEND = '/api/affiliates'
export const ENDPOINT_PACKS = '/api/packs'

