import React, { Component } from "react";

import {
  fetchFaq,
  createFaq,
  uploadImage,
  deleteFaq,
} from "./store/actions";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import Button from "../../../ui/Button";
import { dataURItoBlob } from "../../../setup/helpers";

import { XMarkIcon } from "@heroicons/react/20/solid";

class FaqAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        question: "",
        answer: "",
      },
    };
  }

  componentDidMount() {
    this.props.fetchFaq();
  }

  addPartner = () => {
    this.props.createFaq({
      question: this.state.item.question,
      answer: this.state.item.answer,
    });

    this.setState({ item: { question: "", answer: "" } });
  };

  removePartner = (id) => {
    this.props.deleteFaq(id);
  };

  render() {
    return (
      <Row className="justify-content-center max-w-4xl mx-auto mb-12">
        <Col lg="12">
          <h1 className="display-2 mb-4">Modifier la FAQ</h1>
          <div className="mb-4">
            {this.props.items?.map((item) => (
              <div key={item.id} className="border rounded p-3 relative mb-3">
                <div
                  className="absolute -top-2 -right-2 p-1 bg-white rounded-full border cursor-pointer"
                  onClick={() => this.removePartner(item.id)}
                >
                  <XMarkIcon className="w-4 text-neutral-800" />
                </div>
                <h2 className="mb-1 font-medium">{item.question}</h2>
                <div className="text-sm">{item.answer}</div>
              </div>
            ))}
          </div>
          <div className="border p-4 rounded">
            <div className="mb-3">
              <div className="flex flex-col">
                <label htmlFor="name">Question</label>
                <input
                  id="name"
                  name="name"
                  className="border p-2 rounded mb-2"
                  value={this.state.item.question}
                  onChange={(evt) => {
                    const value = evt.target.value;
                    this.setState((prevState) => ({
                      item: {
                        ...prevState.item,
                        question: value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="title">Réponse</label>
                <textarea
                  id="title"
                  name="title"
                  className="border p-2 rounded mb-2"
                  value={this.state.item.answer}
                  onChange={(evt) => {
                    const value = evt.target.value;
                    this.setState((prevState) => ({
                      item: {
                        ...prevState.item,
                        answer: value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <Button
              onClick={this.addPartner}
              disabled={
                !this.state.item?.question?.length ||
                !this.state.item?.answer?.length
              }
            >
              Ajouter une question
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

// Get data from reducers
const mapStateToProps = (state) => state.faq;

export default connect(mapStateToProps, {
  fetchFaq,
  createFaq,
  deleteFaq,
  uploadImage,
})(FaqAdmin);
