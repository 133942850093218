import React, { Component } from "react";

import {
  fetchTeamPartners,
  createTeamPartner,
  uploadImage,
  deleteTeamPartner,
} from "./store/actions";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import Button from "../../../ui/Button";
import { dataURItoBlob } from "../../../setup/helpers";
import InputImageField from "../../../ui/InputImageField";

import { XMarkIcon } from "@heroicons/react/20/solid";

class TeamPartners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        name: "",
        title: "",
        image: null,
      },
    };
  }

  componentDidMount() {
    this.props.fetchTeamPartners();
  }

  addPartner = () => {
    this.props.createTeamPartner({
      name: this.state.item.name,
      title: this.state.item.title,
      image: this.props.image,
    });

    this.setState({ item: { name: "", title: "", image: null } });
  };

  imageChange = (file) => {
    let formData = new FormData();
    formData.append("file", dataURItoBlob(file));
    this.props.uploadImage(formData);
  };

  removePartner = (id) => {
    this.props.deleteTeamPartner(id);
  };

  render() {
    return (
      <Row className="justify-content-center max-w-4xl mx-auto mb-12">
        <Col lg="12">
          <h1 className="display-2 mb-4">Modifier les partenaires</h1>
          <div className="flex flex-wrap items-start gap-4 mb-4">
            {this.props.items?.map((item) => (
              <div key={item.id} className="w-40 border rounded p-2 relative">
                <div
                  className="absolute -top-2 -right-2 p-1 bg-white rounded-full border cursor-pointer"
                  onClick={() => this.removePartner(item.id)}
                >
                  <XMarkIcon className="w-4 text-neutral-800" />
                </div>
                <img src={item.image} className="w-full h-auto mb-2" alt="" />
                <h2 className="mb-1">{item.name}</h2>
                <div className="text-sm">{item.title}</div>
              </div>
            ))}
          </div>
          <div className="border p-4 rounded">
            <div className="flex space-x-4 mb-3">
              <InputImageField
                name="image"
                input={{
                  value: this.state.item.image,
                  onChange: this.imageChange,
                }}
                meta={{}}
                placeholder="Extension : jpg, png ou gif"
                label="Photo de la personne"
              />
              <div className="flex flex-col">
                <label htmlFor="name">Nom</label>
                <input
                  id="name"
                  name="name"
                  className="border p-2 rounded mb-2"
                  value={this.state.item.name}
                  onChange={(evt) => {
                    const value = evt.target.value;
                    this.setState((prevState) => ({
                      item: {
                        ...prevState.item,
                        name: value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="title">Titre</label>
                <input
                  id="title"
                  name="title"
                  className="border p-2 rounded mb-2"
                  value={this.state.item.title}
                  onChange={(evt) => {
                    const value = evt.target.value;
                    this.setState((prevState) => ({
                      item: {
                        ...prevState.item,
                        title: value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <Button
              onClick={this.addPartner}
              disabled={
                !this.state.item?.name?.length ||
                !this.state.item?.title?.length ||
                !this.props.image
              }
            >
              Ajouter une personne
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

// Get data from reducers
const mapStateToProps = (state) => state.teamPartners;

export default connect(mapStateToProps, {
  fetchTeamPartners,
  createTeamPartner,
  deleteTeamPartner,
  uploadImage,
})(TeamPartners);
