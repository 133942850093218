export const LOGIN_ACCOUNT_INIT = 'LOGIN_ACCOUNT_INIT'
export const LOGIN_ACCOUNT_FAILED = 'LOGIN_ACCOUNT_FAILED'
export const LOGIN_ACCOUNT_SUCCEDED = 'LOGIN_ACCOUNT_SUCCEDED'

export const RESET_ACCOUNT_INIT = 'RESET_ACCOUNT_INIT'
export const RESET_ACCOUNT_FAILED = 'RESET_ACCOUNT_FAILED'
export const RESET_ACCOUNT_SUCCEDED = 'RESET_ACCOUNT_SUCCEDED'

export const EDIT_ACCOUNT_PASSWORD_INIT = 'EDIT_ACCOUNT_PASSWORD_INIT'
export const EDIT_ACCOUNT_PASSWORD_FAILED = 'EDIT_ACCOUNT_PASSWORD_FAILED'
export const EDIT_ACCOUNT_PASSWORD_SUCCEDED = 'EDIT_ACCOUNT_PASSWORD_SUCCEDED'

export const UPDATE_PROFILE_SUCCEDED = 'UPDATE_PROFILE_SUCCEDED'
export const SUBSCRIPTION_SUCCEDED = 'SUBSCRIPTION_SUCCEDED'

export const CREATE_ACCOUNT_INIT = 'CREATE_ACCOUNT_INIT'
export const CREATE_ACCOUNT_FAILED = 'CREATE_ACCOUNT_FAILED'
export const CREATE_ACCOUNT_SUCCEDED = 'CREATE_ACCOUNT_SUCCEDED'

export const FETCH_ACCOUNT_INIT = 'FETCH_ACCOUNT_INIT'
export const FETCH_ACCOUNT_FAILED = 'FETCH_ACCOUNT_FAILED'
export const FETCH_ACCOUNT_SUCCEDED = 'FETCH_ACCOUNT_SUCCEDED'

export const ADD_APPOINTMENT_SUCCEDED = 'ADD_APPOINTMENT_SUCCEDED'
export const REMOVE_APPOINTMENT_SUCCEDED = 'REMOVE_APPOINTMENT_SUCCEDED'

export const ENDPOINT_LOGIN = "/api/auth/login"
export const ENDPOINT_RESET = "/api/auth/password/request-reset"
export const ENDPOINT_EDIT_PASSWORD = "/api/auth/password/reset"
export const ENDPOINT_CREATE = "/api/auth/register"
export const ENDPOINT_TOKEN = "/api/users/token/:param"
export const ENDPOINT_CHECK_EMAIL = "/api/auth/emails/verify"

export const CLEAR_AUTH_STORE = "CLEAR_AUTH_STORE"
