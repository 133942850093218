import React, { Component } from "react";

import {
  fetchTeamMembers,
  createTeamMember,
  uploadImage,
  deleteTeamMember,
} from "./store/actions";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import Button from "../../../ui/Button";
import { dataURItoBlob } from "../../../setup/helpers";
import InputImageField from "../../../ui/InputImageField";

import { XMarkIcon } from "@heroicons/react/20/solid";
import TeamPartners from "../partners/TeamPartners";

class TeamMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        name: "",
        title: "",
        image: null,
      },
    };
  }

  componentDidMount() {
    this.props.fetchTeamMembers();
  }

  addMember = () => {
    this.props.createTeamMember({
      name: this.state.item.name,
      title: this.state.item.title,
      image: this.props.image,
    });

    this.setState({ item: { name: "", title: "", image: null } });
  };

  imageChange = (file) => {
    let formData = new FormData();
    formData.append("file", dataURItoBlob(file));
    this.props.uploadImage(formData);
  };

  removeMember = (id) => {
    this.props.deleteTeamMember(id);
  };

  render() {
    return (
      <>
        <Row className="justify-content-center mb-12 max-w-4xl mx-auto">
          <Col lg="12">
            <h1 className="display-2 mb-4">Modifier l'équipe</h1>
            <div className="flex flex-wrap items-start gap-4 mb-4">
              {this.props.items?.map((item) => (
                <div key={item.id} className="w-40 border rounded p-2 relative">
                  <div
                    className="absolute -top-2 -right-2 p-1 bg-white rounded-full border cursor-pointer"
                    onClick={() => this.removeMember(item.id)}
                  >
                    <XMarkIcon className="w-4 text-neutral-800" />
                  </div>
                  <img src={item.image} className="w-full h-auto mb-2" alt="" />
                  <h2 className="mb-1">{item.name}</h2>
                  <div className="text-sm">{item.title}</div>
                </div>
              ))}
            </div>
            <div className="border p-4 rounded">
              <div className="flex space-x-4 mb-3">
                <InputImageField
                  name="image"
                  input={{
                    value: this.state.item.image,
                    onChange: this.imageChange,
                  }}
                  meta={{}}
                  placeholder="Extension : jpg, png ou gif"
                  label="Photo de la personne"
                />
                <div className="flex flex-col">
                  <label htmlFor="name">Nom</label>
                  <input
                    id="name"
                    name="name"
                    className="border p-2 rounded mb-2"
                    value={this.state.item.name}
                    onChange={(evt) => {
                      const value = evt.target.value;
                      this.setState((prevState) => ({
                        item: {
                          ...prevState.item,
                          name: value,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="title">Titre</label>
                  <input
                    id="title"
                    name="title"
                    className="border p-2 rounded mb-2"
                    value={this.state.item.title}
                    onChange={(evt) => {
                      const value = evt.target.value;
                      this.setState((prevState) => ({
                        item: {
                          ...prevState.item,
                          title: value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <Button
                onClick={this.addMember}
                disabled={
                  !this.state.item?.name?.length ||
                  !this.state.item?.title?.length ||
                  !this.props.image
                }
              >
                Ajouter une personne
              </Button>
            </div>
          </Col>
        </Row>
        <TeamPartners />
      </>
    );
  }
}

// Get data from reducers
const mapStateToProps = (state) => state.teamMembers;

export default connect(mapStateToProps, {
  fetchTeamMembers,
  createTeamMember,
  deleteTeamMember,
  uploadImage,
})(TeamMembers);
