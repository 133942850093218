import React from 'react'
import PropTypes from 'prop-types'

import { FormGroup, InputGroup } from 'reactstrap'

import InputError from './InputError'
import InputLabel from './InputLabel'

import './styles/input-textarea-field.scss'

const InputTextareaField = ({input, label, placeholder, row=4, meta = {}}) => {

  const { touched, error, warning } = meta;

  return (
    <FormGroup className="lr-input-textarea">
      { label && <InputLabel>{label}</InputLabel>}
      <InputGroup className={`${touched && error && "has-danger"}`}>
        <textarea {...input} rows={row} className={`form-control ${touched && error && "has-danger"}`} placeholder={placeholder} />
      </InputGroup>
      <InputError>
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </InputError>
    </FormGroup>
  )
}

InputTextareaField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  row: PropTypes.number,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  })
}


export default InputTextareaField
