import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { updateContent, uploadImage, resetImage } from "../store/actions";
import InputImageField from "../../../../ui/InputImageField";
import { dataURItoBlob } from "../../../../setup/helpers";

function ImageContentInput(props) {
  const {
    label,
    contentKey,
    image: storeImage,
    updateContent,
    uploadImage,
  } = props;

  const [dirty, setDirty] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    window.addEventListener("save-content", save);
  }, [image]);

  useEffect(() => {
    if (dirty) setImage(storeImage);
    setDirty(false);
  }, [storeImage]);

  const save = () => {
    if (contentKey && image) {
      updateContent(contentKey, { key: contentKey, image });
      resetImage();
    }
  };

  const imageChange = (file) => {
    setDirty(true);
    let formData = new FormData();
    formData.append("file", dataURItoBlob(file));
    uploadImage(formData);
  };

  return (
    <InputImageField
      name="image"
      input={{
        value: image,
        onChange: imageChange,
      }}
      meta={{}}
      placeholder="Extension : jpg, png ou gif"
      label={label}
    />
  );
}

// Get data from reducers
const mapStateToProps = (state) => ({ ...state.content });

export default connect(mapStateToProps, { uploadImage, updateContent, resetImage })(
  ImageContentInput
);
