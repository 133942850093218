import { lazy } from 'react'

// App Imports
const FAQ = lazy(() => import('../../../modules/anonymous/FAQ'))
const Location = lazy(() => import('../../../modules/anonymous/Location'))
const ContactUs = lazy(() => import('./../../../modules/anonymous/ContactUs'))
const Withdrawal = lazy(() => import('./../../../modules/anonymous/Withdrawal'))
const PrivatizePlace = lazy(() => import('./../../../modules/anonymous/PrivatizePlace'))
const Actualities = lazy(() => import('./../../../modules/common/actualities/Actualities'))
const Actuality = lazy(() => import('./../../../modules/common/actualities/Actuality'))

// FAQ - routes
export const faq = {
  path: '/faq',
  component: FAQ
}

export const location = {
  path: '/lieu',
  component: Location
}

export const contactUs = {
  path: '/contacter',
  component: ContactUs
}

export const withdrawal = {
  path: '/retractation',
  component: Withdrawal
}

export const privatizePlace = {
  path: '/privatiser',
  component: PrivatizePlace
}

export const actualities = {
  path: '/actualities',
  component: Actualities
}

export const actuality = {
  path: '/actualities/:param',
  component: Actuality
}
