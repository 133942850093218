import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteService,
  fetchServices,
  updateService,
  uploadImage,
  resetImage,
} from "./store/actions";
import { Col, Row } from "reactstrap";
import Button from "../../../ui/Button";
import { dataURItoBlob } from "../../../setup/helpers";
import InputImageField from "../../../ui/InputImageField";
import RichInputField from "../../../ui/RichInputField";

function ServiceAdmin(props) {
  const {
    items,
    image,
    fetchServices,
    updateService,
    deleteService,
    uploadImage,
    resetImage,
  } = props;

  useEffect(fetchServices, []);

  const service = items?.find(
    (service) => service.id === parseInt(props.match.params.param)
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState();

  useEffect(() => {
    setTitle(service?.title);
    setDescription(service?.description);
    setContent(service?.content);
  }, [service]);

  const imageChange = (file) => {
    let formData = new FormData();
    formData.append("file", dataURItoBlob(file));
    uploadImage(formData);
  };

  const handleUpdate = () => {
    const payload = {
      title,
      description,
      content,
    };
    if (image) payload.icon = image;
    updateService(service.id, payload);
    resetImage();
  };

  const handleDelete = () => {
    deleteService(service.id);
  }

  if (!service) return null;

  return (
    <Row className="justify-content-center max-w-4xl mx-auto mb-12">
      <Col lg="12">
        <div className="border p-4 rounded">
          <div className="mb-3">
            <InputImageField
              name="image"
              input={{
                value: image,
                onChange: imageChange,
              }}
              meta={{}}
              placeholder="Extension : jpg, png ou gif"
              label="Icône"
            />
            <div className="flex flex-col">
              <label htmlFor="name">Titre</label>
              <input
                id="name"
                name="name"
                className="border p-2 rounded mb-2"
                value={title}
                onChange={(evt) => setTitle(evt.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="title">Description</label>
              <textarea
                id="title"
                name="title"
                className="border p-2 rounded mb-2"
                value={description}
                onChange={(evt) => setDescription(evt.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <RichInputField
                initialValue={service.content}
                label="Contenu de la page"
                height={400}
                onChange={setContent}
              />
            </div>
          </div>
          <div className="flex space-x-4">
            <Button onClick={handleUpdate}>Sauvegarder</Button>
            <Button onClick={handleDelete} outline color="danger">Supprimer</Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

// Get data from reducers
const mapStateToProps = (state) => state.services;

export default connect(mapStateToProps, {
  fetchServices,
  updateService,
  deleteService,
  uploadImage,
  resetImage,
})(ServiceAdmin);
