export const FETCH_SERVICES_INIT = "FETCH_SERVICES_INIT";
export const FETCH_SERVICES_SUCCEEDED = "FETCH_SERVICES_SUCCEEDED";
export const FETCH_SERVICES_FAILED = "FETCH_SERVICES_FAILED";

export const UPDATE_SERVICE_INIT = "UPDATE_SERVICE_INIT";
export const UPDATE_SERVICE_SUCCEEDED = "UPDATE_SERVICE_SUCCEEDED";
export const UPDATE_SERVICE_FAILED = "UPDATE_SERVICE_FAILED";

export const UPLOAD_IMAGE_INIT = "UPLOAD_IMAGE_INIT";
export const UPLOAD_IMAGE_SUCCEEDED = "UPLOAD_IMAGE_SUCCEEDED";
export const UPLOAD_IMAGE_FAILED = "UPLOAD_IMAGE_FAILED";
export const UPLOAD_IMAGE_RESET = "UPLOAD_IMAGE_RESET";

export const REMOVE_SERVICE_INIT = "REMOVE_SERVICE_INIT";
export const REMOVE_SERVICE_SUCCEEDED = "REMOVE_SERVICE_SUCCEEDED";
export const REMOVE_SERVICE_FAILED = "REMOVE_SERVICE_FAILED";

export const CREATE_SERVICE_INIT = "CREATE_SERVICE_INIT";
export const CREATE_SERVICE_SUCCEEDED = "CREATE_SERVICE_SUCCEEDED";
export const CREATE_SERVICE_FAILED = "CREATE_SERVICE_FAILED";

export const ENDPOINT_SERVICE = "/api/services";
export const ENDPOINT_SERVICE_ID = "/api/services/:id";
export const ENDPOINT_UPLOAD = "/api/upload";
