import axios from "axios";
import { APP_URL_API } from "./config/env";
import { ROLES } from "./constants";
import moment from 'moment';

// Helpers

/**
 * Render element or component by provided condition
 * @param {*} condition
 * @param {*} renderFn
 */
export function renderIf(condition, renderFn) {
  return condition ? renderFn() : null;
}

/**
 * Duplicate object
 * @param {*} object
 */
export function duplicate(object) {
  return Object.assign({}, object);
}

export const clean = (obj) => {
  const object = duplicate(obj);
  for (var propName in object) {
    if (object[propName] === null || object[propName] === undefined) {
      delete object[propName];
    }
  }
  return object;
};

/**
 * Set user token and info in localStorage
 * @param {*} token : user token
 * @param {*} user : user data
 */
export const setLocalStorageData = (token, user) => {
  // Update token
  window.localStorage.setItem("token", token);
  window.localStorage.setItem("user", JSON.stringify(user));
};

/**
 * update user info in localStorage
 * @param {*} token : user token
 * @param {*} user : user data
 */
export const updateLocalStorageData = (user) => {
  // Update token
  window.localStorage.setItem("user", JSON.stringify(user));
};

/**
 * Get user token and info in localStorage
 */
export const getLocalStorageData = () => {
  // Update token
  const accessToken = window.localStorage.getItem("token") || null;
  const currentUserJson = window.localStorage.getItem("user") || null;

  const currentUser = JSON.parse(currentUserJson);

  return {
    accessToken,
    currentUser,
    isAuthenticated: accessToken ? true : false,
    ...loadRole(currentUser && currentUser.role),
  };
};

/**
 * Clear localStorage
 */
export const clearLocalStorageData = () => {
  // Remove token
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("user");
};

export const loadRole = (role = null) => {
  const roles = {
    isSuperAdmin: false,
    isAdmin: false,
    isGuide: false,
    isUser: false,
  };
  const roleRegex = new RegExp(role, "g");

  if (roleRegex.test(ROLES.ADMIN)) {
    roles.isAdmin = true;
  }
  if (roleRegex.test(ROLES.SUPER_ADMIN)) {
    roles.isSuperAdmin = true;
  }
  if (roleRegex.test(ROLES.GUIDE)) {
    roles.isGuide = true;
  }
  if (roleRegex.test(ROLES.USER)) {
    roles.isUser = true;
  }

  return roles;
};

/**
 * Check if object is empty
 * @param {*} obj
 */
export function isEmpty(obj) {
  let name;
  for (name in obj) {
    if (obj.hasOwnProperty(name)) {
      return false;
    }
  }
  if (obj.constructor !== Object) {
    return false;
  }
  return true;
}

const api = axios.create({
  baseURL: APP_URL_API,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * Make Api call
 *
 * @param method string
 * @param url string
 * @param data Object
 * @param headers Object
 * @param params Object
 */
export const makeApiCall = (
  method,
  url,
  data = {},
  headers = {},
  params = {},
  extra = {}
) =>
  api.request({
    method,
    data,
    url,
    headers,
    params,
    ...extra,
  });

/**
 * Convert data uri to blob object
 *
 */
export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const s2ab = (s) => {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export const scrollTop = () => {
  document.getElementsByClassName("page-content")[0].scrollTop = 0;
};

export const jsUcfirst = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const downloadFile = (data, filename, mime, bom) => {
  let blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  let blob = new Blob(blobData, { type: mime || "application/octet-stream" });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    let blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    let tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

export const formatNumber = (x) => Number.parseFloat(x).toFixed(2);

export const updateChartLabel = (obj = {}, labels) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (labels[key]) {
      newObj[labels[key]] = obj[key];
    }
  });
  return newObj;
};
/**
 * format a date Object to pass it as YYYY-MM-DD parameter to moment
 * @param {*} date
 */
export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function formatDateForEvent(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month].join('/');
}
/**
 * format a date Object to pass it as YYYY-MM-DD parameter to moment
 * @param {*} date
 */
export function formatDateRange(date) {
  if (new Date(date).toString() === 'Invalid Date') {
    const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre']
    date = date.toString().split(" ")
    date.shift()
    for (let [index, item] of months.entries()) {
      if (item === date[1]) {
        date[1] = JSON.stringify(index + 1)
        break
      }
    }
    let _date = ""
    for (let item of date) {
      _date += item + " "
    }
    date = _date
  }
  date = moment(new Date(date)).set({ hour: 12, minute: 0 }).toDate().toISOString()
  return date;
}
