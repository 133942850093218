import * as ACTIONS from '../constants'


// Initial State
const pricingInitialState = {
  isLoading: false,
  packs: [],
  success: false,
  error: null
}


/**
 * Update pricing state
 * @param  {} state: pricingInitialState
 * @param  {} action
 */
const pricingReducer = (state = pricingInitialState, action) => {
  const { payload, type } = action
  switch (type) {
    case ACTIONS.CLEAR_PRICING_STORE : {
      return { ...state, ...pricingInitialState }
    }

    case ACTIONS.REFER_FRIEND_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.REFER_FRIEND_SUCCEDED : {
      const { success } = payload
      return { ...state, isLoading: false, success }
    }
    case ACTIONS.REFER_FRIEND_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.FETCH_PACKS_LIST_INIT : {
      return { ...state, isLoading: true, error: null, packs: [] }
    }
    case ACTIONS.FETCH_PACKS_LIST_SUCCEDED : {
      return { ...state, packs: payload.data, isLoading: false }
    }
    case ACTIONS.FETCH_PACKS_LIST_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    default: {
      return state
    }
  }
}

export default pricingReducer
