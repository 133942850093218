import { lazy } from 'react'

const Home = lazy(() => import('./../../../modules/user/home/Home'))


export const home = {
  path: '/',
  component: Home,
  auth: false
}
