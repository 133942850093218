import { lazy } from 'react'

const Services = lazy(() => import('../../../modules/user/services/Services'))
const Service = lazy(() => import('../../../modules/user/services/Service'))


export const services = {
  path: '/partenaires',
  component: Services,
  auth: false
}

export const service = {
  path: '/partenaires/:id',
  component: Service,
  auth: false
}
