import * as ACTIONS from '../constants'

// Initial State
const membershipInitialState = {
  error: null,
  success: false,
  isLoading: false,
}

/**
 * Update membership store
 * @param  {} state: membershipInitialState
 * @param  {} action
 */
const membershipReducer = (state = membershipInitialState, action) => {
  const { payload, type } = action
  switch  (type) {
    case ACTIONS.SUBSCRIPTION_INIT : {
      return { ...state, isLoading: true, error: null, packs: [] }
    }
    case ACTIONS.SUBSCRIPTION_SUCCEDED : {
      return { ...state, success: payload.success, isLoading: false }
    }
    case ACTIONS.SUBSCRIPTION_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    default: {
      return state
    }
  }
}

export default membershipReducer
