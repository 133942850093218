export const FETCH_ALL_PROGRAMS_INIT = "ADMIN_FETCH_ALL_PROGRAMS_INIT";
export const FETCH_ALL_PROGRAMS_SUCCEDED = "ADMIN_FETCH_ALL_PROGRAMS_SUCCEDED";
export const FETCH_ALL_PROGRAMS_FAILED = "ADMIN_FETCH_ALL_PROGRAMS_FAILED";

export const FETCH_ALL_USERS_INIT = "ADMIN_FETCH_ALL_USERS_INIT";
export const FETCH_ALL_USERS_SUCCEDED = "ADMIN_FETCH_ALL_USERS_SUCCEDED";
export const FETCH_ALL_USERS_FAILED = "ADMIN_FETCH_ALL_USERS_FAILED";

export const ADD_PARTICIPATION_INIT = "ADMIN_ADD_PARTICIPATION_INIT";
export const ADD_PARTICIPATION_SUCCEDED = "ADMIN_ADD_PARTICIPATION_SUCCEDED";
export const ADD_PARTICIPATION_FAILED = "ADMIN_ADD_PARTICIPATION_FAILED";

export const CLEAR_STORE = "ADMIN_APPOINTMENT_CLEAR_STORE";

export const ENDPOINT_ALL_PROGRAMS = "/api/activities/all";
export const ENDPOINT_ALL_USERS = "/api/users/all";
export const ENDPOINT_PARTICIPATIONS = "/api/inscriptions";
