export const SOCIAL_SUPPORT = "social";
export const PSYCHOLOGICAL_SUPPORT = "psychologique";

export const SUPPORTS_FILTER = [
  { value: SOCIAL_SUPPORT, label: "Accompagnement social" },
  { value: PSYCHOLOGICAL_SUPPORT, label: "Accompagnement psychologique" },
];

export const SUPPORTS_OPTIONS = [
  { value: SOCIAL_SUPPORT, label: "Social" },
  { value: PSYCHOLOGICAL_SUPPORT, label: "Psychologique" },
];

export const SUPPORTS_ITEMS = {
  [SOCIAL_SUPPORT]: {
    title: "Accompagnement social",
    description: "Un espace de parole et d'écoute avec Magali",
    price: 0,
    type: SOCIAL_SUPPORT,
    with: "Magali",
    phone: "06 63 87 16 95",
    moreDetails:
      "Magali, travailleur social, vous propose un accompagnement et un suivi personnalisé sur le plan social pour favoriser une évolution positive dans divers domaines : accès aux droits, à la santé, aux loisirs, au logement... Elle est disponible en physique, par téléphone ou par mail, en fonction de ce qui vous convient le mieux !",
    contact:
      "Pour toutes questions, vous pouvez contacter Magali au 06 63 87 16 95",
  },
  [PSYCHOLOGICAL_SUPPORT]: {
    title: "Accompagnement psychologique",
    description:
      "Un espace de parole et d'écoute avec Stéphanie qui est présente tous les jeudis matin à La Ressourcerie. En dehors de ce créneau, tout échange se fera par téléphone.",
    price: 0,
    with: "Stéphanie",
    type: PSYCHOLOGICAL_SUPPORT,
    moreDetails:
      "Stéphanie, psychologue diplômée exerçant depuis 20 ans, est là pour vous accompagner et vous guider selon vos besoins. Elle vous propose des entretiens physiques, téléphoniques ou par mail, en fonction de ce qui vous convient le mieux ! Elle saura aussi vous réorienter vers d'autres professionnels si nécessaire.",
    contact:
      "Pour toutes questions, vous pouvez contacter Stéphanie au 07 66 07 75 91",
  },
};
