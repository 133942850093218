import { lazy } from 'react'

import { ROLES } from './../../constants'

const MyProfile = lazy( () => import('./../../../modules/admin/profile/MyProfile'))

// User Space routes
export const myProfile = {
  path: '/admin/mon-compte',
  component: MyProfile,
  auth: true,
  role: [ ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN ]
}


