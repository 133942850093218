import { CALL_API, CLEAR_USER_CREDENTIALS } from "../constants";
import { makeApiCall } from "../helpers";

export const api = (store) => (next) => (action) => {
  if (!action || action.type !== CALL_API) {
    return next(action);
  }

  const {
    actions,
    extraActions = {},
    payload = {},
    headers = {},
    endpoint,
    method,
    auth,
    params,
    extra = {},
  } = action.meta;
  const { accessToken = null } = store.getState().auth;

  if (actions.init) {
    next(create_action(actions.init));
  }

  if (auth && accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  makeApiCall(method, endpoint, action.payload, headers, params, extra)
    .then((resp) => {
      next(
        create_action(
          actions.success,
          Object.keys(payload).length
            ? Object.assign({}, payload, resp.data)
            : resp.data || action.payload
        )
      );
      if (extraActions.success) {
        next(create_action(extraActions.success, resp.data || action.payload));
      }
    })
    .catch((err) => {
      if (err.response.data.error === "jwt expired") {
        // TODO - log out user
        console.log("JWT Token expired");
        next(
          create_action(
            CLEAR_USER_CREDENTIALS,
            (err.response && err.response.data) || {}
          )
        );
      } else {
        next(
          create_action(actions.fail, (err.response && err.response.data) || {})
        );
      }
      if (extraActions.fail) {
        next(
          create_action(
            extraActions.fail,
            (err.response && err.response.data) || {}
          )
        );
      }
    });
};

const create_action = (type, payload = {}) => ({
  type,
  payload,
});

export default api;
