import { lazy } from 'react'

// App Imports
import { ROLES } from '../../constants'


const Actualities = lazy(async () => import('../../../modules/admin/actualities/Actualities'))
const ActualitiesNew = lazy(async () => import('../../../modules/admin/actualities/ActualitiesNew'))
const ActualitiesEdit = lazy(async () => import('../../../modules/admin/actualities/ActualitiesEdit'))


// Admin actualities routes
export const allActualities = {
    path: '/list-actualities',
    component: Actualities,
    auth: true,
    role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN]
}

export const createActuality = {
    path: '/create-actuality',
    component: ActualitiesNew,
    auth: true,
    role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN]
}

export const editActuality = {
    path: '/edit-actuality/:param',
    component: ActualitiesEdit,
    auth: true,
    role: [ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN]
}
