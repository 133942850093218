export const FETCH_PROGRAMS_INIT = 'FETCH_PROGRAMS_INIT'
export const FETCH_PROGRAMS_SUCCEDED = 'FETCH_PROGRAMS_SUCCEDED'
export const FETCH_PROGRAMS_FAILED = 'FETCH_PROGRAMS_FAILED'

export const FETCH_PROGRAM_INIT = 'FETCH_PROGRAM_INIT'
export const FETCH_PROGRAM_SUCCEDED = 'FETCH_PROGRAM_SUCCEDED'
export const FETCH_PROGRAM_FAILED = 'FETCH_PROGRAM_FAILED'

export const ADD_PARTICIPATION_INIT = "ADD_PARTICIPATION_INIT"
export const ADD_PARTICIPATION_SUCCEDED = "ADD_PARTICIPATION_SUCCEDED"
export const ADD_PARTICIPATION_FAILED = "ADD_PARTICIPATION_FAILED"

export const REMOVE_PARTICIPATION_INIT = "REMOVE_PARTICIPATION_INIT"
export const REMOVE_PARTICIPATION_SUCCEDED = "REMOVE_PARTICIPATION_SUCCEDED"
export const REMOVE_PARTICIPATION_FAILED = "REMOVE_PARTICIPATION_FAILED"

export const ENDPOINT_PROGRAMS = "/api/activities"
export const ENDPOINT_PARTICIPATION = "/api/inscriptions"
export const ENDPOINT_PROGRAM = "/api/activities/:param"
