export const FETCH_TEAM_MEMBERS_INIT = "FETCH_TEAM_MEMBERS_INIT";
export const FETCH_TEAM_MEMBERS_SUCCEEDED = "FETCH_TEAM_MEMBERS_SUCCEEDED";
export const FETCH_TEAM_MEMBERS_FAILED = "FETCH_TEAM_MEMBERS_FAILED";

export const UPDATE_TEAM_MEMBER_INIT = "UPDATE_TEAM_MEMBER_INIT";
export const UPDATE_TEAM_MEMBER_SUCCEEDED = "UPDATE_TEAM_MEMBER_SUCCEEDED";
export const UPDATE_TEAM_MEMBER_FAILED = "UPDATE_TEAM_MEMBER_FAILED";

export const UPLOAD_IMAGE_INIT = "UPLOAD_IMAGE_INIT";
export const UPLOAD_IMAGE_SUCCEEDED = "UPLOAD_IMAGE_SUCCEEDED";
export const UPLOAD_IMAGE_FAILED = "UPLOAD_IMAGE_FAILED";
export const UPLOAD_IMAGE_RESET = "UPLOAD_IMAGE_RESET";

export const REMOVE_TEAM_MEMBER_INIT = "REMOVE_TEAM_MEMBER_INIT";
export const REMOVE_TEAM_MEMBER_SUCCEEDED = "REMOVE_TEAM_MEMBER_SUCCEEDED";
export const REMOVE_TEAM_MEMBER_FAILED = "REMOVE_TEAM_MEMBER_FAILED";

export const CREATE_TEAM_MEMBER_INIT = "CREATE_TEAM_MEMBER_INIT";
export const CREATE_TEAM_MEMBER_SUCCEEDED = "CREATE_TEAM_MEMBER_SUCCEEDED";
export const CREATE_TEAM_MEMBER_FAILED = "CREATE_TEAM_MEMBER_FAILED";

export const ENDPOINT_TEAM_MEMBERS = "/api/team-members";
export const ENDPOINT_TEAM_MEMBER = "/api/team-members/:id";
export const ENDPOINT_UPLOAD = "/api/upload";
