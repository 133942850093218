import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup } from 'reactstrap'

import InputError from './InputError'
import InputLabel from './InputLabel'

import imageIcon from './images/image.svg'
import loaderIcon from './images/loader.gif'

import './styles/input-image-field.scss'

const validURL = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

const InputImageField = ({ input: { value: omitValue, ...inputProps }, isLoading, label, placeholder, meta: { touched, error, warning } }) => {

  const [file, setFile] = React.useState(null)

  React.useEffect(() => {
    if (!omitValue) {
      setFile("")
    }

    if (validURL(omitValue)) {
      setFile(new File([], omitValue.split("/").pop()))
    }
  }, [omitValue])

  React.useEffect(() => {
    return function cleanup() {
      setFile("")
    }
  }, [])

  const adaptFileEventToValue = delegate => e => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0])
      reader.onload = () => {
        delegate(reader.result)
      }
      setFile(e.target.files[0])
    }
  }

  let uploadInputElem = React.useRef()

  return (
    <FormGroup className="lr-input-image-field">
      { label && <InputLabel>{label}</InputLabel>}
      <input
        ref={uploadInputElem}
        type="file"
        className="d-none"
        {...inputProps}
        onChange={adaptFileEventToValue(inputProps.onChange)}
        onBlur={adaptFileEventToValue(inputProps.onBlur)}
      />
      <InputGroup className={`${touched && error && "has-danger"}`}>
        <input
          readOnly="on"
          onClick={() => uploadInputElem.current.click()}
          className={`form-control ${touched && error && "has-danger"}`}
          value={file ? file.name : ""}
          type="text"
          placeholder={placeholder}
        />
        <div onClick={() => uploadInputElem.current.click()} className="lr-input-image-field__icon">
          <img src={isLoading ? loaderIcon : imageIcon} alt="..." />
        </div>
      </InputGroup>
      <InputError>
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </InputError>
    </FormGroup>
  )
}

InputImageField.propTypes = {
  input: PropTypes.object,
  icon: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  })
}

export default InputImageField
