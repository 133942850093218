export const FETCH_TEAM_PARTNERS_INIT = "FETCH_TEAM_PARTNERS_INIT";
export const FETCH_TEAM_PARTNERS_SUCCEEDED = "FETCH_TEAM_PARTNERS_SUCCEEDED";
export const FETCH_TEAM_PARTNERS_FAILED = "FETCH_TEAM_PARTNERS_FAILED";

export const UPDATE_TEAM_PARTNER_INIT = "UPDATE_TEAM_PARTNER_INIT";
export const UPDATE_TEAM_PARTNER_SUCCEEDED = "UPDATE_TEAM_PARTNER_SUCCEEDED";
export const UPDATE_TEAM_PARTNER_FAILED = "UPDATE_TEAM_PARTNER_FAILED";

export const UPLOAD_IMAGE_INIT = "UPLOAD_IMAGE_INIT";
export const UPLOAD_IMAGE_SUCCEEDED = "UPLOAD_IMAGE_SUCCEEDED";
export const UPLOAD_IMAGE_FAILED = "UPLOAD_IMAGE_FAILED";
export const UPLOAD_IMAGE_RESET = "UPLOAD_IMAGE_RESET";

export const REMOVE_TEAM_PARTNER_INIT = "REMOVE_TEAM_PARTNER_INIT";
export const REMOVE_TEAM_PARTNER_SUCCEEDED = "REMOVE_TEAM_PARTNER_SUCCEEDED";
export const REMOVE_TEAM_PARTNER_FAILED = "REMOVE_TEAM_PARTNER_FAILED";

export const CREATE_TEAM_PARTNER_INIT = "CREATE_TEAM_PARTNER_INIT";
export const CREATE_TEAM_PARTNER_SUCCEEDED = "CREATE_TEAM_PARTNER_SUCCEEDED";
export const CREATE_TEAM_PARTNER_FAILED = "CREATE_TEAM_PARTNER_FAILED";

export const ENDPOINT_TEAM_PARTNERS = "/api/partners";
export const ENDPOINT_TEAM_PARTNER = "/api/partners/:id";
export const ENDPOINT_UPLOAD = "/api/upload";
