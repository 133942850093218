export const SUBSCRIBE_TO_NEWSLETTER_INIT = "SUBSCRIBE_TO_NEWSLETTER_INIT";
export const SUBSCRIBE_TO_NEWSLETTER_FAILED = "SUBSCRIBE_TO_NEWSLETTER_FAILED";
export const SUBSCRIBE_TO_NEWSLETTER_SUCCEDED =
  "SUBSCRIBE_TO_NEWSLETTER_SUCCEDED";

export const FETCH_LATEST_PROGRAMS_INIT = "FETCH_LATEST_PROGRAMS_INIT";
export const FETCH_LATEST_PROGRAMS_SUCCEDED = "FETCH_LATEST_PROGRAMS_SUCCEDED";
export const FETCH_LATEST_PROGRAMS_FAILED = "FETCH_LATEST_PROGRAMS_FAILED";

export const ENDPOINT_NEWSLETTER = "/api/newsletters";
export const ENDPOINT_PROGRAMS = "/api/activities";
export const ENDPOINT_UPCOMING_ACTIVITIES = "/api/activities/preview";
