import { lazy } from 'react'

// App Imports
import { ROLES } from '../../../setup/constants'


const Stats =  lazy(() => import('../../../modules/admin/stats/Stats'))


// User stats routes
export const stats = {
  path: '/chiffres-cles',
  component: Stats,
  auth: true,
  role: [ ROLES.SUPER_ADMIN, ROLES.GUIDE, ROLES.ADMIN ]
}
