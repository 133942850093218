import { lazy } from 'react'

// App Imports
const Supports = lazy(() => import('../../../modules/user/support/Supports'))
const Support = lazy(() => import('../../../modules/user/support/Support'))


// User Supports routes
export const supports = {
  path: '/accompagnement',
  component: Supports,
  auth: false
}


// User Supports routes
export const support = {
  path: '/accompagnement/:param',
  component: Support,
  auth: false
}
