import * as ACTIONS from '../constants'

// Initial State
const userInitialState = {
  error: null,
  success: null,
  isFetching: false,
  searchResult: [],
  isLoading: false,
  activitiesLoading: true,
  isUploading: false,
  isDownloading: false,
  file: null,
  appointments: [],
  userActivities: [],
  items: [],
  total: 0,
  limit: 0,
  activitiesTotal: 0,
  activitiesLimit: 0,
  page: 0,
  pages: 0,
  activitiesPages: 0,
  activitiesPage: 0,
  item: null,
  searchTerm: '',
  resetUsersFilters: false,
}

/**
 * Update user store
 * @param  {} state: userInitialState
 * @param  {} action
 */
const userReducer = (state = userInitialState, action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_USER_STORE: {
      return { ...state, success: false, item: null, error: null, isLoading: false }
    }

    case ACTIONS.FLASH_MESSAGES: {
      return { ...state, success: false, error: null, isLoading: false }
    }

    case ACTIONS.FETCH_USERS_INIT: {
      return { ...state, isFetching: true, items: [], error: null }
    }
    case ACTIONS.FETCH_USERS_SUCCEDED: {
      const { users: items, total, limit, page, pages } = payload.data
      return { ...state, items, isFetching: false, total, pages, limit, page, error: null }
    }
    case ACTIONS.FETCH_USERS_FAILED: {
      return { ...state, error: payload, isFetching: false, items: [] }
    }

    case ACTIONS.SEARCH_USERS_INIT: {
      return { ...state, isFetching: true, items: [], error: null }
    }
    case ACTIONS.SEARCH_USERS_SUCCEDED: {
      const { users: items, total, limit, page, pages, term } = payload.data
      return { ...state, searchResult: items, isFetching: false, totalS: total, pagesS: pages, limitS: limit, pageS: page, error: null, searchTerm: term }
    }
    case ACTIONS.SEARCH_USERS_FAILED: {
      return { ...state, error: payload, isFetching: false, items: [] }
    }

    case ACTIONS.FETCH_USER_INIT: {
      return { ...state, isFetching: true, item: null, error: null }
    }
    case ACTIONS.FETCH_USER_SUCCEDED: {
      if (payload.data.birthdate) {
        payload.data.day = new Date(payload.data.birthdate).getDate();
        payload.data.month = (new Date(payload.data.birthdate).getMonth() + 1).toString();
        payload.data.year = new Date(payload.data.birthdate).getFullYear();
      } else {
        payload.data.day = null;
        payload.data.month = null;
        payload.data.year = null;
      }

      if (payload.data.lastVisit !== null) {
        payload.data.visitday = new Date(payload.data.lastVisit).getDate();
        payload.data.visitmonth = (new Date(payload.data.lastVisit).getMonth() + 1).toString();
        payload.data.visityear = new Date(payload.data.lastVisit).getFullYear();
      }

      if (payload.data.subscribedUntil !== null) {
        payload.data.subday = new Date(payload.data.subscribedUntil).getDate();
        payload.data.submonth = (new Date(payload.data.subscribedUntil).getMonth() + 1).toString();
        payload.data.subyear = new Date(payload.data.subscribedUntil).getFullYear();
      }
      return { ...state, item: payload.data, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_USER_FAILED: {
      return { ...state, error: payload, success: false, isFetching: false, item: null }
    }

    case ACTIONS.DELETE_USER_INIT: {
      return { ...state, isLoading: true, item: null, error: null }
    }
    case ACTIONS.DELETE_USER_SUCCEDED: {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_USER_FAILED: {
      return { ...state, error: payload, isLoading: false, item: null }
    }

    case ACTIONS.DOWNLOAD_USERS_INIT: {
      return { ...state, isDownloading: true, error: null }
    }
    case ACTIONS.DOWNLOAD_USERS_SUCCEDED: {
      return { ...state, file: payload, isDownloading: false, error: null }
    }
    case ACTIONS.DOWNLOAD_USERS_FAILED: {
      return { ...state, error: payload, isDownloading: false }
    }

    case ACTIONS.CREATE_USER_INIT: {
      return { ...state, error: null, success: null, isLoading: true }
    }
    case ACTIONS.CREATE_USER_SUCCEDED: {
      return { ...state, item: payload.data, isLoading: false, success: true, error: null }
    }
    case ACTIONS.CREATE_USER_FAILED: {
      return { ...state, success: false, error: payload, isLoading: false }
    }

    case ACTIONS.BUY_PACK_SUCCEDED: {
      return {
        ...state,
        item: state.item && { ...state.item, wallet: { ...state.item.wallet, credits: state.item.wallet.credits + payload.data.value } },
        isLoading: false
      }
    }

    case ACTIONS.FETCH_USER_APPOINTMENTS_INIT: {
      return { ...state, appointments: [], error: null }
    }
    case ACTIONS.FETCH_USER_APPOINTMENTS_SUCCEDED: {
      const { appointments, total, limit, page, pages } = payload.data
      return { ...state, appointments, total, limit, page, pages, error: null }
    }
    case ACTIONS.FETCH_USER_APPOINTMENTS_FAILED: {
      return { ...state, error: payload, items: [] }
    }

    case ACTIONS.EDIT_USER_APPOINTMENTS_INIT: {
      return { ...state, error: null, success: null, isLoading: true }
    }
    case ACTIONS.EDIT_USER_APPOINTMENTS_SUCCEDED: {
      return { ...state, isLoading: false, success: true, error: null }
    }
    case ACTIONS.EDIT_USER_APPOINTMENTS_FAILED: {
      return { ...state, success: false, error: payload, isLoading: false }
    }

    case ACTIONS.SET_USER_SUBSCRIPTION_INIT: {
      return { ...state, error: null, success: null, isLoading: true }
    }
    case ACTIONS.SET_USER_SUBSCRIPTION_SUCCEDED: {
      return { ...state, item: { ...state.item, subscribed: payload.subscribed }, isLoading: false, success: true, error: null }
    }
    case ACTIONS.SET_USER_SUBSCRIPTION_FAILED: {
      return { ...state, success: false, error: payload, isLoading: false }
    }

    case ACTIONS.EDIT_USER_INIT: {
      return { ...state, error: null, success: null, isLoading: true }
    }
    case ACTIONS.EDIT_USER_SUCCEDED: {
      if (payload.data.birthdate) {
        payload.data.day = new Date(payload.data.birthdate).getDate();
        payload.data.month = (new Date(payload.data.birthdate).getMonth() + 1).toString();
        payload.data.year = new Date(payload.data.birthdate).getFullYear();
      } else {
        payload.data.day = null;
        payload.data.month = null;
        payload.data.year = null;
      }

      if (payload.data.lastVisit !== null) {
        payload.data.visitday = new Date(payload.data.lastVisit).getDate();
        payload.data.visitmonth = (new Date(payload.data.lastVisit).getMonth() + 1).toString();
        payload.data.visityear = new Date(payload.data.lastVisit).getFullYear();
      }

      if (payload.data.subscribedUntil !== null) {
        payload.data.subday = new Date(payload.data.subscribedUntil).getDate();
        payload.data.submonth = (new Date(payload.data.subscribedUntil).getMonth() + 1).toString();
        payload.data.subyear = new Date(payload.data.subscribedUntil).getFullYear();
      }

      const result = {
        ...state.item,
        ...payload.data,
      }

      return { ...state, item: result, isLoading: false, success: true, error: null }
    }
    case ACTIONS.EDIT_USER_FAILED: {
      return { ...state, success: false, error: payload, isLoading: false }
    }

    case ACTIONS.ADD_APPOINTMENT_SUCCEDED: {
      return { ...state, appointments: state.appointments.concat(payload.data), isLoading: false, error: null }
    }

    case ACTIONS.FETCH_ACTIVITIES_INIT: {
      return { ...state, error: null, success: null, activitiesLoading: true }
    }
    case ACTIONS.FETCH_ACTIVITIES_SUCCEDED: {
      return { ...state, ...payload.data, activitiesLoading: false, success: true, error: null }
    }
    case ACTIONS.FETCH_ACTIVITIES_FAILED: {
      return { ...state, success: false, error: payload, activitiesLoading: false }
    }

    case ACTIONS.RESET_USERS_FILTERS: {
      return { ...state, resetUsersFilters: payload, searchResult: [], searchTerm: '' }
    }

    default: {
      return state
    }
  }
}

export default userReducer
