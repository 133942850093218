import * as ACTIONS from "../constants";

// Initial State
const homeInitialState = {
  isLoading: false,
  items: [],
  success: false,
  error: null,
};

/**
 * Update anaymous state
 * @param  {} state: homeInitialState
 * @param  {} action
 */
const homeReducer = (state = homeInitialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.SUBSCRIBE_TO_NEWSLETTER_INIT: {
      return { ...state, isLoading: true, success: false, codeError: 0 };
    }
    case ACTIONS.SUBSCRIBE_TO_NEWSLETTER_SUCCEDED: {
      const { success } = payload;
      return { ...state, isLoading: false, success };
    }
    case ACTIONS.SUBSCRIBE_TO_NEWSLETTER_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.FETCH_LATEST_PROGRAMS_INIT: {
      return { ...state, isLoading: true, items: [], item: null, codeError: 0 };
    }
    case ACTIONS.FETCH_LATEST_PROGRAMS_SUCCEDED: {
      const items = payload.data;
      return { ...state, items, isLoading: false, error: null };
    }
    case ACTIONS.FETCH_LATEST_PROGRAMS_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default homeReducer;
