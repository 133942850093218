import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import InputError from './InputError'
import InputLabel from './InputLabel'

import eyeIcon from './images/eye.svg'
import noEyeIcon from './images/no-eye.svg'

import './styles/input-field.scss'

const InputField = ({input, label, readOnly=false, icon, placeholder, type, showError=true, meta = {} }) => {

  const { touched, error, warning } = meta;

  const [ inputType, setInputType ] = React.useState(type)
  
  const onPickOnPassword = () => {
    if (inputType === "text") {
      setInputType("password")
    }else {
      setInputType("text")
    }
  }

  return (
    <FormGroup className="lr-form-input">
      { label && <InputLabel>{label}</InputLabel>}
      <InputGroup className={`${(touched && error && showError) && "has-danger"}`}>
        { icon &&
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <img src={icon} className="input-icon" alt="..." />
          </InputGroupText>
        </InputGroupAddon>
        }
        <input
          {...input}
          readOnly={ readOnly } 
          className={`form-control ${(touched && error && showError) && "has-danger"}`} 
          type={inputType} 
          placeholder={placeholder} 
        />
        { type === "password" && <div onClick={ onPickOnPassword } className="lr-form-input__password">
          <img src={ (inputType !== "text") ? eyeIcon : noEyeIcon} alt="..." />
        </div> }
      </InputGroup>
      { showError && <InputError>
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </InputError>
      }
    </FormGroup>
  )
}

InputField.propTypes = {
  input: PropTypes.object,
  icon: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  })
}

export default InputField
