// Imports
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// App Imports
import configureStore from './setup/store'
import { getLocalStorageData } from './setup/helpers'

import { routes } from './setup/routes'

// Global scss
import './ui/styles/common/custom-bootstrap.scss'
import './ui/styles/tailwind.scss'

// Page loader
import PageLoader from './ui/PageLoader'

// Error pages
const NotFoundPage = React.lazy( () => import('./modules/common/errorPages/NotFoundPage'))

// Access Control
const AccessControl = React.lazy( () => import('./modules/common/security/AccessControl'))

// main layout
const Layout = React.lazy( () => import('./modules/common/Layout'))

const store = configureStore({
  auth: getLocalStorageData()
})

render(
  <Provider store={store}>
    <Router>
      <React.Suspense fallback={<PageLoader />}>
        <Layout>
          <Switch>
            {Object.values(routes).map((route, index) => route.path && (
              route.auth
                ? <AccessControl { ...route } key={index} path={typeof route.path === 'function' ? route.path() : route.path} />
                : <Route exact { ...route } key={index} path={typeof route.path === 'function' ? route.path() : route.path} />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </React.Suspense>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
