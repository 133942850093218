import * as ACTIONS from '../constants'

// Initial State
const profileInitialState = {
  error: null,
  success: false,
  isLoading: false,
  isUploading: false,
  currentUser: null,
  activities: [],
  appointments: [],
  packs: [],
  page:0,
  pages: 0,
}

/**
 * Update profile store
 * @param  {} state: profileInitialState
 * @param  {} action
 */
const profileReducer = (state = profileInitialState, action) => {
  const { payload, type } = action
  switch  (type) {

    case ACTIONS.CLEAR_PROFILE_STORE : {
      return { ...state, error: null, success: false, uploadComplete: false }
    }

    case ACTIONS.FETCH_CURRENT_USER_INIT : {
      return { ...state, isLoading: true, error: null, currentUser: null }
    }
    case ACTIONS.FETCH_CURRENT_USER_SUCCEDED : {
      const { data: currentUser } = payload
      return { ...state, currentUser, isLoading: false }
    }
    case ACTIONS.FETCH_CURRENT_USER_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.UPDATE_PROFILE_INIT : {
      return { ...state, isLoading: true, error: null, success: false, uploadComplete: false }
    }
    case ACTIONS.UPDATE_PROFILE_SUCCEDED : {
      const { success, data: currentUser } = payload
      return { ...state, success, currentUser, isLoading: false }
    }
    case ACTIONS.UPDATE_PROFILE_FAILED : {
      
      return { ...state, error: payload, success: false, isLoading: false }
    }

    case ACTIONS.UPLOAD_AVATAR_INIT : {
      return { ...state, error: null, success: false, isUploading: true }
    }
    case ACTIONS.UPLOAD_AVATAR_SUCCEDED : {
      const { file } = payload.data
      return { ...state, currentUser: { ...state.currentUser, avatar: file }, isUploading: false }
    }
    case ACTIONS.UPLOAD_AVATAR_FAILED : {
      return { ...state, error: { ...payload, uploadFailed: true }, isUploading: false }
    }

    case ACTIONS.FETCH_MY_ACTIVITIES_INIT : {
      return { ...state, isLoading: true, error: null, activities: [], page: 0, pages: 0 }
    }
    case ACTIONS.FETCH_MY_ACTIVITIES_SUCCEDED : {
      const { activities, page, pages } = payload.data
      return { ...state, activities, page, pages, isLoading: false }
    }
    case ACTIONS.FETCH_MY_ACTIVITIES_FAILED : {
      
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.FETCH_MY_APPOINTMENT_INIT : {
      return { ...state, isLoading: true, error: null, appointments: [], page: 0, pages: 0 }
    }
    case ACTIONS.FETCH_MY_APPOINTMENT_SUCCEDED : {
      const { appointments, page, pages } = payload.data
      return { ...state, appointments, page, pages, isLoading: false }
    }
    case ACTIONS.FETCH_MY_APPOINTMENT_FAILED : {
      
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.BUY_PACK_SUCCEDED : { 
      return { 
        ...state,
        currentUser: state.currentUser && { ...state.currentUser, wallet: { ...state.currentUser.wallet, credits: state.currentUser.wallet.credits + payload.data.value }}, 
        isLoading: false 
      }
    }

    case ACTIONS.SUBSCRIPTION_SUCCEDED : {
      return { ...state, currentUser: state.currentUser && { ...state.currentUser, subscribed: true } }
    }

    case ACTIONS.ADD_PARTICIPATION_SUCCEDED : {
      if (payload.data.paymentMethod === "credits") {
        return {
          ...state,
          currentUser: state.currentUser && { 
            ...state.currentUser, 
            wallet: { ...state.currentUser.wallet, credits: state.currentUser.wallet.credits - (payload.data.credits || 0) }
          },
        }
      }
      return state
    }

    case ACTIONS.REMOVE_PARTICIPATION_SUCCEDED : {
      if (payload.data.paymentMethod === "credits") {
        return {
          ...state,
          currentUser: state.currentUser && { 
            ...state.currentUser, 
            wallet: { ...state.currentUser.wallet, credits: state.currentUser.wallet.credits + (payload.data.credits || 0) }
          },
        }
      }
      return state
    }

    case ACTIONS.CLEAR_AUTH_STORE : {
      return profileInitialState
    }

    default: {
      return state
    }
  }
}

export default profileReducer
