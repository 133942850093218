import * as support from "./support";
import * as activity from "./activity";
import * as availabitity from "./availability";
import * as user from "./user";
import * as pricing from "./pricing";
import * as errorCode from "./errorCode";

export const DATA = {
  ...support,
  ...availabitity,
  ...user,
  ...pricing,
  ...activity,
  ...errorCode,
};

export const CALL_API = "CALL_API";
export const SAVE_USER_CREDENTIALS = "SAVE_USER_CREDENTIALS";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

export const CLEAR_USER_CREDENTIALS = "CLEAR_USER_CREDENTIALS";

export const APP_VERSION = "1.0.4";

export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const ROLES = user.ROLES;

export const ENDPOINT_CHECK_EMAIL = "/api/auth/emails/verify";
