import * as ACTIONS from '../constants'

// Initial State
const supportInitialState = {
  availabilities: [],
  appointment: null,
  hasAppointment: false,
  error: null,
  success: false,
  isLoading: false,
}

/**
 * Update support store
 * @param  {} state: supportInitialState
 * @param  {} action
 */
const supportReducer = (state = supportInitialState, action) => {
  const { payload, type } = action
  switch  (type) {

    case ACTIONS.ADD_APPOINTMENT_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.ADD_APPOINTMENT_SUCCEDED : {
      return { ...state, success: payload.success, isLoading: false, error: null }
    }
    case ACTIONS.ADD_APPOINTMENT_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.REMOVE_APPOINTMENT_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.REMOVE_APPOINTMENT_SUCCEDED : {
      return { ...state, success: payload.success, hasAppointment: false, isLoading: false, error: null }
    }
    case ACTIONS.REMOVE_APPOINTMENT_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.FETCH_AVAILABILITIES_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.FETCH_AVAILABILITIES_SUCCEDED : {
      return { ...state, availabilities: payload.data, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_AVAILABILITIES_FAILED : {
      return { ...state, error: payload.error, isLoading: false }
    }


    default: {
      return state
    }
  }
}

export default supportReducer
