import { lazy } from 'react'

import { ROLES } from './../../constants'

const MyAccount = lazy(() => import('./../../../modules/user/profile/MyAccount'))
const MyActivities = lazy(() => import('./../../../modules/user/profile/MyActivities'))
const MyAppointments = lazy(() => import('./../../../modules/user/profile/MyAppointments'))

// User Space routes
export const myAccount = {
  path: '/mon-espace/mon-compte',
  component: MyAccount,
  auth: true,
  role: ROLES.USER
}

export const myActivities = {
  path: '/mon-espace/mes-activites',
  component: MyActivities,
  auth: true,
  role: ROLES.USER
}

export const myAppointments = {
  path: '/mon-espace/mes-rdvs',
  component: MyAppointments,
  auth: true,
  role: ROLES.USER
} 
