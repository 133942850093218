export const CONTACT_US_INIT = 'CONTACT_US_INIT'
export const CONTACT_US_FAILED = 'CONTACT_US_FAILED'
export const CONTACT_US_SUCCEDED = 'CONTACT_US_SUCCEDED'

export const WITH_DRAWAL_INIT = 'WITH_DRAWAL_INIT'
export const WITH_DRAWAL_FAILED = 'WITH_DRAWAL_FAILED'
export const WITH_DRAWAL_SUCCEDED = 'WITH_DRAWAL_SUCCEDED'

export const PRIVATIZE_PLACE_INIT = 'PRIVATIZE_PLACE_INIT'
export const PRIVATIZE_PLACE_FAILED = 'PRIVATIZE_PLACE_FAILED'
export const PRIVATIZE_PLACE_SUCCEDED = 'PRIVATIZE_PLACE_SUCCEDED'

export const ENDPOINT_CONTACTUS = '/api/contact'
export const ENDPOINT_WITHDRAWAL = '/api/contact'
export const ENDPOINT_PRIVATIZE_PLACE = '/api/contact'

export const CLEAR_ANONYMOUS_STORE = 'CLEAR_ANONYMOUS_STORE'
