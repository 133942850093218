import React from 'react'
import PropTypes from 'prop-types'

import { useHistory } from "react-router-dom"

import { Spinner } from 'reactstrap'
import Button from './Button'

import './styles/fixed-button.scss'


const FixedButton = ({ disabled, isLoading }) => {

  const history = useHistory()

  const onClick = () => {
    history.goBack()
  }

  return (
  <div className="lr-fixed-button">
    <div className="d-flex lr-fixed-button__buttons">
      <div>
        <Button type="button" color="white-no-border" onClick={ onClick }> Annuler </Button>
      </div>
      <div className="ml-auto">
        <Button type="submit" disabled={ disabled } color="primary">
          { isLoading && <Spinner className="mr-2 button-spinner" color="white" /> }
          Enregistrer
        </Button>
      </div>
    </div>
  </div>
)
}

FixedButton.propTypes = {
  disable: PropTypes.bool, 
  isLoading: PropTypes.bool, 
}

export default FixedButton
