export const FETCH_FAQ_INIT = "FETCH_FAQ_INIT";
export const FETCH_FAQ_SUCCEEDED = "FETCH_FAQ_SUCCEEDED";
export const FETCH_FAQ_FAILED = "FETCH_FAQ_FAILED";

export const UPDATE_FAQ_INIT = "UPDATE_FAQ_INIT";
export const UPDATE_FAQ_SUCCEEDED = "UPDATE_FAQ_SUCCEEDED";
export const UPDATE_FAQ_FAILED = "UPDATE_FAQ_FAILED";

export const UPLOAD_IMAGE_INIT = "UPLOAD_IMAGE_INIT";
export const UPLOAD_IMAGE_SUCCEEDED = "UPLOAD_IMAGE_SUCCEEDED";
export const UPLOAD_IMAGE_FAILED = "UPLOAD_IMAGE_FAILED";
export const UPLOAD_IMAGE_RESET = "UPLOAD_IMAGE_RESET";

export const REMOVE_FAQ_INIT = "REMOVE_FAQ_INIT";
export const REMOVE_FAQ_SUCCEEDED = "REMOVE_FAQ_SUCCEEDED";
export const REMOVE_FAQ_FAILED = "REMOVE_FAQ_FAILED";

export const CREATE_FAQ_INIT = "CREATE_FAQ_INIT";
export const CREATE_FAQ_SUCCEEDED = "CREATE_FAQ_SUCCEEDED";
export const CREATE_FAQ_FAILED = "CREATE_FAQ_FAILED";

export const ENDPOINT_FAQ = "/api/faq";
export const ENDPOINT_FAQ_ID = "/api/faq/:id";
export const ENDPOINT_UPLOAD = "/api/upload";
