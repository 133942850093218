// App Imports
import * as content from './content'
import * as programs from './programs'
import * as appointment from './appointment'
import * as profile from './profile'
import * as user from './user'
import * as stats from './stats'
import * as actuality from './actualities'


// Admin routes
const admin = {
  ...content,
  ...programs,
  ...appointment,
  ...profile,
  ...user,
  ...stats,
  ...actuality,
}

export default admin

