import moment from 'moment'
import { jsUcfirst } from './../helpers'

import 'moment/locale/fr'

export const PROFESSIONAL_STATUS = [
  { value: "Libérale", label: "Libérale" },
  { value: "Salarié", label: "Salarié" },
  { value: "fonctionnaire", label: "Fonctionnaire" },
  { value: "En recherche d'emploi", label: "En recherche d'emploi" },
  { value: "provisoirement sans activité", label: "Provisoirement sans activité" },
  { value: "Étudiant", label: "Étudiant" },
  { value: "Retraité", label: "Retraité" }
]

export const USER_TYPE_NAMES = {
  users: "Inscrits",
  helpers: "Aidants",
  nonHelpers: "Non-aidants",
  subscribed: "Adhérents",
  nonSubscribed: "Non-adhérents"
}

export const GENDER = [{ value: 1, label: "Femme" }, { value: 2, label: "Homme" }]

export const CARE_GIVER_NAMES = {
  1: "Aidant(e)",
  2: "Non-aidant(e)",
  3: "A été aidant(e)"
}
export const CARE_GIVER = [
  { value: 1, label: "Oui" },
  { value: 2, label: "Non" },
  { value: 3, label: "J'ai été aidant(e)" }
]
export const CARE_GIVER_TYPES = [
  { value: 1, label: "Aidant(e)" },
  { value: 2, label: "Non-aidant(e)" },
  { value: 3, label: "a été aidant(e)" }
]

export const HEARD_ABOUT_US_NAMES = {
  1: "Famille, amis ...",
  2: "En passant devant",
  3: "Facebook",
  4: "Partenaire (prestataire, mairie, Aidomi...)",
  5: "Recherche Google",
  6: "Bouche à Oreille",
  7: "Corps médical",
  8: "MH (Actions, Listing, Employés)",
  9: "Service d'aide ou dispositif déjà en place à domicile"
}


export const HEARD_ABOUT_US = [
  { value: 1, label: "Famille, amis ..." },
  { value: 2, label: "En passant devant" },
  { value: 3, label: "Facebook" },
  { value: 4, label: "Partenaire (prestataire, mairie, Aidomi...)" },
  { value: 5, label: "Recherche Google" },
  { value: 6, label: "Bouche à Oreille" },
  { value: 7, label: "Corps médical" },
  { value: 8, label: "MH (Actions, Listing, Employés)" },
  { value: 9, label: "Service d'aide ou dispositif déjà en place à domicile" }
]

export const ROLES = {
  SUPER_ADMIN: 'SuperAdmin',
  ADMIN: 'Manager',
  GUIDE: 'Guide',
  USER: 'SimpleUser'
}

export const ROLES_LIST = [
  { value: ROLES.ADMIN, label: "Gestionnaire de Lieu" },
  { value: ROLES.GUIDE, label: "Accompagnateur" },
]

export const SUBSCRIBED = [
  { value: true, label: "Adhérents" },
  { value: false, label: "Non-adhérents" },
]

export const MALAKOFF_HUMANIS = [
  { value: true, label: "Oui" },
  { value: false, label: "Non" },
]

export const ZIP_CODE_33 = [
  { value: true, label: "33" },
  { value: false, label: "Autres" },
]

export const MONTHS = [
  { label: '', value: null },
  ...Array.apply(0, Array(12)).map((_, i) =>
  ({
    label: jsUcfirst(moment().locale('fr').month(i).format('MMMM')),
    value: moment().locale('fr').month(i).format('M')
  })
  )
]
