import * as ACTIONS from '../constants'

// Initial State
const tokenInitialState = {
  error: null,
  success: false,
  isLoading: false,
  packs: []
}

/**
 * Update token store
 * @param  {} state: tokenInitialState
 * @param  {} action
 */
const tokenReducer = (state = tokenInitialState, action) => {
  const { payload, type } = action
  switch  (type) {
    case ACTIONS.FETCH_PACKS_INIT : {
      return { ...state, isLoading: true, error: null, packs: [] }
    }
    case ACTIONS.FETCH_PACKS_SUCCEDED : {
      return { ...state, packs: payload.data, isLoading: false }
    }
    case ACTIONS.FETCH_PACKS_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.BUY_PACK_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.BUY_PACK_SUCCEDED : {
      const { success } = payload
      return { ...state, success, isLoading: false }
    }
    case ACTIONS.BUY_PACK_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    default: {
      return state
    }
  }
}

export default tokenReducer
