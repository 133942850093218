export const FETCH_SUPPORTS_INIT = 'FETCH_SUPPORTS_INIT'
export const FETCH_SUPPORTS_SUCCEDED = 'FETCH_SUPPORTS_SUCCEDED'
export const FETCH_SUPPORTS_FAILED = 'FETCH_SUPPORTS_FAILED'

export const FETCH_SUPPORT_INIT = 'FETCH_SUPPORT_INIT'
export const FETCH_SUPPORT_SUCCEDED = 'FETCH_SUPPORT_SUCCEDED'
export const FETCH_SUPPORT_FAILED = 'FETCH_SUPPORT_FAILED'

export const ADD_APPOINTMENT_INIT = "ADD_APPOINTMENT_INIT"
export const ADD_APPOINTMENT_SUCCEDED = "ADD_APPOINTMENT_SUCCEDED"
export const ADD_APPOINTMENT_FAILED = "ADD_APPOINTMENT_FAILED"

export const REMOVE_APPOINTMENT_INIT = "REMOVE_APPOINTMENT_INIT"
export const REMOVE_APPOINTMENT_SUCCEDED = "REMOVE_APPOINTMENT_SUCCEDED"
export const REMOVE_APPOINTMENT_FAILED = "REMOVE_APPOINTMENT_FAILED"

export const FETCH_APPOINTMENT_INIT = "FETCH_APPOINTMENT_INIT"
export const FETCH_APPOINTMENT_SUCCEDED = "FETCH_APPOINTMENT_SUCCEDED"
export const FETCH_APPOINTMENT_FAILED = "FETCH_APPOINTMENT_FAILED"

export const FETCH_AVAILABILITIES_INIT = "FETCH_AVAILABILITIES_INIT"
export const FETCH_AVAILABILITIES_SUCCEDED = "FETCH_AVAILABILITIES_SUCCEDED"
export const FETCH_AVAILABILITIES_FAILED = "FETCH_AVAILABILITIES_FAILED"

export const ENDPOINT_SUPPORTS = "/api/supports"
export const ENDPOINT_APPOINTMENTS = "/api/appointments"
export const ENDPOINT_APPOINTMENT = "/api/appointments/:param"
export const ENDPOINT_AVAILABILITIES = "/api/availabilities"
export const ENDPOINT_SUPPORT = "/api/supports/:param"
