export const FETCH_PACKS_INIT = 'FETCH_PACKS_INIT'
export const FETCH_PACKS_SUCCEDED = 'FETCH_PACKS_SUCCEDED'
export const FETCH_PACKS_FAILED = 'FETCH_PACKS_FAILED'

export const BUY_PACK_INIT = 'BUY_PACK_INIT'
export const BUY_PACK_SUCCEDED = 'BUY_PACK_SUCCEDED'
export const BUY_PACK_FAILED = 'BUY_PACK_FAILED'

export const ENDPOINT_PACKS = "/api/packs"
export const ENDPOINT_BUY_PACK = "/api/packs/:param/buy"
